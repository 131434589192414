import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function VentasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const [text, setText] = useState(false);

  const [cliente, setCliente] = useState([]);
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [nota, setNota] = useState("");
  const [importe, setImporte] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [value, setValue] = useState('');
  const [validaBoton, setValidaBoton] = useState(true);

  const clear = () => {
    setCliente([]);
    setFecha("");
    setDescripcion("");
    setNota("");
    setImporte("");
    setSelectedCliente("");
    setValidaBoton(true)
  };

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setCliente(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveFactura = (event) => {
    event.preventDefault();
    setValidaBoton(false)
    axios
      .post(
        URL_FACTURAS,
        {
          recurrente: "No",
          fecha,
          descripcion,
          nota,
          importe,
          clientes: value._id,
          saldo:importe
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Nueva Nota',
            detalle: `${value._id}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true)
      });
  };

  const options = cliente.map((option) => {
    const junta = option.apellido + " " + option.nombre + " / " + option.nombre_comercial
    const firstLetter = option.apellido[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })

  return (
    <>
      <Header />
      <br />
      {user.facturas_cargos_create ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">Crea una Nota</h3>
            <Form onSubmit={saveFactura}>
              <Row>
                <Col>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.apellido} {option.nombre} / {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Label>Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    placeholder="Importe"
                    value={importe}
                    required
                    onChange={(e) => {
                      setImporte(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Label>Nota</Label>
                  <Input
                    type="text"
                    value={nota}
                    required
                    onChange={(e) => {
                      setNota(e.target.value);
                    }}
                  />
                </Col>
                </Row>
                <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Descripcion</Label>
                  <Input
                    className="col-sm-12"
                    type="textarea"
                    placeholder="Descripcion"
                    value={descripcion}
                    required
                    onChange={(e) => {
                      setDescripcion(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
              {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Registrar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Registrar
      </Button>)}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/DetalleNotas"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default VentasCreate;
