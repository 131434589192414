import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ApartadosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_APARTADOS = process.env.REACT_APP_URL_APARTADOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_APARTADOS = process.env.REACT_APP_URL_ARTICULOS_APARTADOS;
  const URL_APARTADOS_ACUMULADOS = process.env.REACT_APP_URL_APARTADOS_ACUMULADOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");

  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [articulosApartado, setArticulosApartado] = useState([]);
  const [fecha_pedido, setFechaPedido] = useState("");
  const [rollosTotales, setRollosTotales] = useState(0);
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);

  const [subTotal, setSubTotal] = useState(0);

  const [idsInventario, setIdsInventario] = useState([]);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventario: "",
      articulos: "",
      idArticulo: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      tono: "",
      idArticuloPedido: "",
      precio:0,
      total:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(`${URL_PEDIDOS}ApartadosCreate`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function calculaApartado() {
    const newArticulosPedido = articulosPedido.map((AP) => {
      cantidadAgrupado.map((c) => {
        if (
          AP.articulos[0]._id == c.idArticulo &&
          AP.colores[0]._id == c.colores&&
          AP.tono == c.tono
        ) {
          AP.apartado = parseFloat(AP.apartado) + parseFloat(c.cantidad);
          AP.pendiente_surtir =
            parseFloat(AP.pendiente_surtir) - parseFloat(c.cantidad);
        }
        return AP;
      });
    });
    setArticulosPedido(newArticulosPedido);
  }

  const saveApartado = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "") {
      setValidaBoton(false);
      toggleProgreso();

      calculaApartado();

      let totalRollos = inputFields.length  + cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_APARTADOS,
            {
              fecha,
              pedidos: selectedPedido,
              user: user.id,
              observaciones,
              total_general:subTotal,
              subTotal,
              iva:0,
              impuestos:0,
              is_active:"Si",
              surtido:"No",
              total_cantidad
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.articulos != "") {
                axios
                  .patch(
                    `${URL_INVENTARIOS}/apartado/${a.idInventario}`,
                    {
                      apartados: data.data._id,
                      apartado: "Si",
                      articulosPedido: a.idArticuloPedido,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Apartado",
                            detalle: `Pedido ${selectedPedido}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Apartado",
                        detalle: `Pedido ${selectedPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });

            // Pegamos a Apartados Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_APARTADOS_ACUMULADOS}`,
                  {
                    apartados: data.data._id,
                    articulos: w.idArticulo,
                    articulosPedido: w.idArticuloPedido,
                    colores: w.colores,
                    cantidad: w.cantidad,
                    pedidos: selectedPedido,
                    fecha,
                    clientes: idCliente,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Apartado",
                          detalle: `Pedido ${selectedPedido}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a apartados acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventario: "",
        articulos: "",
        idArticulo: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        tono: "",
        idArticuloPedido: "",
        precio:0,
        total:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
  };


  function BuscaArticulo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsInventario.includes(event.target.value)) {
        if (
          inputFields.filter((e) => e.idInventario == event.target.value)
            .length > 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El rollo ya fue escaneado!",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.idInventario = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        } else {
          articulosApartado.map((a) => {
            if (a._id == event.target.value) {
              let articulosApartado = a._id;
              let articulo = a.articulo;
              let idArticulo = a.idArticulo;
              let unidadArticulo = a.unidadArticulo;
              let color = a.color;
              let cantidad = a.cantidad;
              let tono = a.tono;
              handleChangeInputArticulo(
                id,
                articulosApartado,
                articulo,
                idArticulo,
                unidadArticulo,
                color,
                cantidad,
                tono
              );
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo no pertenece al pedido!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventario = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventario = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticulo = (
    id,
    articulosApartado,
    articulo,
    idArticulo,
    unidadArticulo,
    color,
    cantidad,
    tono
  ) => {

    let idArticuloPedido;
    let precio

    articulosPedido.map((a) => {
      if (a.articulos[0]._id == idArticulo && a.colores[0]._id == color && a.tono == tono) {
         idArticuloPedido = a._id
         precio = a.precio
      }
    });

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventario = articulosApartado;
        i.articulos = articulo;
        i.idArticulo = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
        i.cantidad = cantidad;
        i.tono = tono;
        i.idArticuloPedido = idArticuloPedido;
        i.precio = precio
        i.total = parseFloat(precio) * parseFloat(cantidad)
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  console.log(inputFields)

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    setTotalCantidad(TM + TK)

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["colores"] + item["tono"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        colores: item.colores,
        tono: item.tono,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].id = item.id;
      groups[val].idInventario = item.idInventario;
      groups[val].articulos = item.articulos;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidad = item.unidad;
      groups[val].colores = item.colores;
      groups[val].tono = item.tono;
      groups[val].idArticuloPedido = item.idArticuloPedido;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

    let tr = 0;
    inputFields.map((a) => {
      if (a.articulos != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  function jalaPedido(e) {
    setSelectedPedido(e);

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtido/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosApartado = res.data;
        setArticulosApartado(allArticulosApartado);
        let result = allArticulosApartado.map(({ _id }) => _id);
        setIdsInventario(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        setArticulosPedido(allArticulosPedido);
      })
      .catch((err) => {
        console.log(err);
      });

    pedidos.map((a) => {
      if (a._id == e) {
        setFechaPedido(a.fecha);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.clientes[0].razon_social);
        setIdCliente(a.clientes[0]._id);
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos_create ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Apartado</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Pedido</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedPedido}
                  required
                  onChange={(e) => jalaPedido(e.target.value)}
                >
                  <option value="0">Selecciona una Pedido</option>
                  {pedidos
                    .sort((a, b) => (a.idPedido < b.idPedido ? 1 : -1))
                    .map((a) => {
                      // if (
                      //   a.status == "Abierto" &&
                      //   a.is_active == "Si" &&
                      //   a.autorizado == "Si"
                      // ) {
                        return (
                          <option value={a._id}>
                            # {a.idPedido} {a.clientes[0].nombre_comercial}
                          </option>
                        );
                      // }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Pedido</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_pedido}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaPedido(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Cliente</Label>
                <Input
                  type="text"
                  placeholder="Cliente"
                  value={cliente}
                  required
                  disabled
                  onChange={(e) => {
                    setCliente(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Tono</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventario"
                      type="string"
                      value={inputField.idInventario}
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="tono"
                      type="string"
                      value={inputField.tono}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}
          </Form>

          <br />
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={saveApartado}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn btn-success"
                  disabled
                  tabindex="-1"
                >
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoApartados"
                className="btn btn-danger"
                id="botonListado"
                tabindex="-1"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ApartadosCreate;
