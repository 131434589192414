import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import moment from "moment";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

import { v4 as uuidv4 } from "uuid";

function ListadoSurtidos() {
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  const [surtidos, setSurtidos] = useState([]);
  const [articulosOC, setArticulosOC] = useState([]);
  const [ordenesSurtido, setOrdenesSurtido] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [pedimento, setPedimento] = useState("");
  const [factura_cliente, setFacturaCliente] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [partida, setPartida] = useState("");
  const [aduana, setAduana] = useState("");
  const [fecha_aduana, setFechaAduana] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_surtido, setNumeroSurtido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");

  const [articulosSurtido, setArticulosSurtido] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOC: "",
      articulos: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      tono: "",
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.pedidos[0].clientes[0].nombre_comercial,
              idClientes: a.pedidos[0].clientes[0]._id,
              colaborador: a.pedidos[0].colaboradores[0].nombre + " " + a.pedidos[0].colaboradores[0].apellido,
              idColaborador: a.pedidos[0].colaboradores[0]._id,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              prefijo: a.empresas[0].prefijo,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.colaborador];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 25, 25);
    doc.text(`Listado de Surtidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Colaborador"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    // doc.save(`Surtidos.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return {
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Colaborador: a.colaborador,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoSurtidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoSurtidos",
        sheetFilter: ["Fecha", "Cliente", "Pedido", "Colaborador"],
        sheetHeader: ["Fecha", "Cliente", "Pedido", "Colaborador"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.colaborador];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 25, 25);
    doc.text(`Listado de Surtidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Colaborador"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Surtidos",
          email: mailTo,
          fileName: "ListadoSurtidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Surtidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    pedimento,
    partida,
    observaciones,
    numero,
    aduana,
    fecha_aduana,
    pais_origen,
    idEdit
  ) {
    setFecha(fecha);
    setPedimento(pedimento);
    setPartida(partida);
    setObservaciones(observaciones);
    setNumeroSurtido(numero);
    setAduana(aduana);
    setFechaAduana(fecha_aduana);
    setPaisOrigen(pais_origen);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    impuestos,
    subTotal,
    iva,
    total_general,
    plazo,
    prefijo,
    empresas
  ) {
    await axios
    .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allArticulosSurtido = response.data;
      let arrayTabla = allArticulosSurtido
        .map((a) => {
          return {
            id: a.inventarios[0]._id,
            articulos:
              a.inventarios[0].articulos[0].codigo +
              " " +
              a.inventarios[0].articulos[0].nombre,
            unidad: a.inventarios[0].articulos[0].unidad,
            colores: a.inventarios[0].colores[0].name,
            cantidad: a.inventarios[0].cantidad,
            cantidadString: ((a.inventarios[0].cantidad).toFixed(2)).toString(),
            idArticulo: a.inventarios[0].articulos[0]._id,
            idColor: a.inventarios[0].colores[0]._id,
            precio: a.precio,
            // descuento: 0,
            tono: a.inventarios[0].tono,
          };
        })
        .filter(function (el) {
          return el != null;
        });

      // let t = arrayTabla.length;
      // let arrayPrecio = arrayTabla.map((a) => {
      // axios
      //   .get(
      //     `${URL_ARTICULOS_PEDIDO}/precio/${idPDFOC}/${a.idArticulo}/${a.idColor}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //       },
      //     }
      //   )
      //   .then((resp) => {
      //     t = t - 1;
      //     let precio = resp.data[0].precio;
      //     let descuento = resp.data[0].descuento;
      //     let idArticulo = resp.data[0].articulos[0]._id;
      //     let idColor = resp.data[0].colores[0]._id;

      //     let arrayP = arrayTabla.map((i) => {
      //       if (i.idArticulo == idArticulo && i.idColor == idColor) {
      //         i.precio = precio;
      //         i.descuento = descuento;
      //       }
      //       return i;
      //     });

      // if (t == 0) {
      let dataFinal = Object.values(arrayTabla);
      let cantidadRollos = dataFinal.length;

      let agrupado = dataFinal.reduce(function (groups, item) {
        const val = item["idArticulo"] + item["idColor"] + item["tono"];
        groups[val] = groups[val] || {
          idArticulo: item.idArticulo,
          idColores: item.idColores,
          cantidad: 0,
          rollos: 0,
          cantidadString: "",
        };
        groups[val].cantidad += item.cantidad;
        groups[val].cantidadString += item.cantidadString + " / ";
        groups[val].articulos = item.articulos;
        groups[val].unidad = item.unidad;
        groups[val].colores = item.colores;
        groups[val].precio = item.precio;
        groups[val].descuento = item.descuento;
        groups[val].tono = item.tono;
        groups[val].rollos += 1;
        return groups;
      }, []);

      let agrupadoValues = Object.values(agrupado);

      let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
      let totalMts = mts.map((c) => parseFloat(c.cantidad));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);
      setTotalMetrosEdit(TM);
      let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
      let totalKG = KG.map((c) => parseFloat(c.cantidad));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);
      setTotalKilosEdit(TK);

      let total_importe = 0;
      let totalParcial = 0;
      let totalDesc = 0;
      const data = agrupadoValues.map((a) => {
        totalParcial = a.cantidad * a.precio;
        totalDesc = totalParcial - (totalParcial * a.descuento) / 100;
        total_importe = total_importe + totalDesc;

        return [
          a.articulos,
          a.colores,
          a.tono,
          a.rollos,
          new Intl.NumberFormat("en-US").format(a.cantidad) + " " + a.unidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.precio),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalParcial),
          a.cantidadString,
        ];
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF("landscape");
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 230, 30, 45, 15);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 130, 15, 25, 25);
      doc.text(`Nota # ${prefijo} ${numero}`, 20, 20);
      doc.setFontSize(12);
      doc.text(`Fecha ${fecha}`, 20, 25);
      doc.text(`Cliente ${clientes}`, 20, 30);
      doc.text(`Pedido # ${pedidos}`, 20, 35);
      doc.text(
        `Metros ${new Intl.NumberFormat("en-US").format(TM)} / Kilos ${new Intl.NumberFormat("en-US").format(TK)} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`,
        20,
        40
      );
      doc.text(`Después de cortada la tela no se aceptan reclamaciones ni devoluciones`, 20, 45);
      doc.autoTable({
        head: [
          [
            "Articulo",
            "Color",
            "Tono",
            "Rollos",
            "Cantidad",
            "Precio",
            "Total",
            "Desgloce Rollos",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [["", "", "", "", "", "Sub Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(subTotal)],
        ["", "", "", "", "", "IVA", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(iva)],
        ["", "", "", "", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total_general)]],
        showFoot: "lastPage",
      });

      /////

      // let agrupadoRollos = dataFinal.reduce(function (groups, item) {
      //   const val = item["idArticulo"] + item["idColor"] + item["tono"]
      //   groups[val] = groups[val] || {
      //     idArticulo: item.idArticulo,
      //     idColores: item.idColores,
      //     tono:"",
      //     cantidadString: "",
      //   };
      //   groups[val].cantidadString += item.cantidadString + " / ";
      //   groups[val].articulos = item.articulos;
      //   groups[val].unidad = item.unidad;
      //   groups[val].colores = item.colores;
      //   groups[val].precio = item.precio;
      //   groups[val].tono = item.tono;
      //   groups[val].descuento = item.descuento;
      //   return groups;
      // }, []);

      // let agrupadoRollosValues = Object.values(agrupadoRollos);
      // const data2 = agrupadoRollosValues.map((a) => {
      //   return [
      //     a.articulos,
      //     a.colores,
      //     a.tono,
      //     a.cantidadString
      //   ];
      // });
      // const dataPDF2 = Object.values(data2);
      // const dataPDFLimpia2 = dataPDF2.filter(function (el) {
      //   return el != null;
      // });
      // doc.autoTable({
      //   head: [
      //     [
      //       "Articulo",
      //       "Color",
      //       "Tono",
      //       "Rollos",
      //     ],
      //   ],
      //   body: dataPDFLimpia2,
      //   foot: [["", "", "", ""]],
      //   showFoot: "lastPage",
      // });

      // //////

      // doc.save(`Surtido-Resumen-${numero}.pdf`);
      //   doc.save(`Nota-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
        // }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Excel Surtido
  async function ExcelOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    prefijo,
    idExcelOC,
    user,
    pedidos
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idExcelOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:
                a.inventarios[0].articulos[0].codigo +
                " " +
                a.inventarios[0].articulos[0].nombre,
              unidad: a.inventarios[0].articulos[0].unidad,
              colores: a.inventarios[0].colores[0].name,
              cantidad: a.inventarios[0].cantidad,
              idArticulo: a.inventarios[0].articulos[0]._id,
              idColor: a.inventarios[0].colores[0]._id,
              precio: 0,
              descuento: 0,
              tono: a.inventarios[0].tono,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let t = arrayTabla.length;
        let arrayPrecio = arrayTabla.map((a) => {
          axios
            .get(
              `${URL_ARTICULOS_PEDIDO}/precio/${idExcelOC}/${a.idArticulo}/${a.idColor}`,
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((resp) => {
              t = t - 1;
              let precio = resp.data[0].precio;
              let descuento = resp.data[0].descuento;
              let idArticulo = resp.data[0].articulos[0]._id;
              let idColor = resp.data[0].colores[0]._id;

              let arrayP = arrayTabla.map((i) => {
                if (i.idArticulo == idArticulo && i.idColor == idColor) {
                  i.precio = precio;
                  i.descuento = descuento;
                }
                return i;
              });

              if (t == 0) {
                let dataFinal = Object.values(arrayP);
                let totalParcial = 0;
                let totalDesc = 0;

                const dataFinalTotales = dataFinal.map((w) => {
                  totalParcial = parseFloat(a.cantidad) * parseFloat(a.precio);
                  totalDesc =
                    parseFloat(totalParcial) -
                    (parseFloat(totalParcial) * parseFloat(a.descuento)) / 100;

                  return {
                    articuloTotal: w.articulos,
                    unidadTotal: w.unidad,
                    colorTotal: w.colores,
                    tonoTotal: w.tono,
                    cantidadTotal: w.cantidad,
                    precioTotal: w.precio,
                    descuentoTotal: w.descuento,
                    totalTotal:
                      w.cantidad * w.precio -
                      (w.cantidad * w.precio * w.descuento) / 100,
                  };
                });

                const dataExcel = dataFinalTotales.map((a) => {
                  return {
                    Articulo: a.articuloTotal,
                    Unidad: a.unidadTotal,
                    Color: a.colorTotal,
                    Tono: a.tonoTotal,
                    Cantidad: a.cantidadTotal,
                    Precio: a.precioTotal,
                    Descuento: a.descuentoTotal,
                    Total: a.totalTotal,
                  };
                });
                const dataExportExcel = Object.values(dataExcel);
                const dataExcelLimpia = dataExportExcel.filter(function (el) {
                  return el != null;
                });

                var option = {};

                option.fileName = `Surtido-${prefijo, numero}`;

                option.datas = [
                  {
                    sheetData: dataExcelLimpia,
                    sheetName: "Surtidos",
                    sheetFilter: [
                      "Articulo",
                      "Unidad",
                      "Color",
                      "Tono",
                      "Cantidad",
                      "Precio",
                      "Descuento",
                      "Total",
                    ],
                    sheetHeader: [
                      "Articulo",
                      "Unidad",
                      "Color",
                      "Tono",
                      "Cantidad",
                      "Precio",
                      "Descuento",
                      "Total",
                    ],
                  },
                ];

                var toExcel = new ExportJsonExcel(option);
                toExcel.saveExcel();
              }
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // async function PDFOCRollo(
  //   fecha,
  //   idClientes,
  //   clientes,
  //   observaciones,
  //   numero,
  //   idPDFOC,
  //   user ) {

  //      await axios
  //       .get(`${URL_INVENTARIOS}/surtidos/${idPDFOC}`, {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       })
  //       .then((response) => {
  //         let allArticulosSurtido = response.data
  //         let arrayTabla = allArticulosSurtido
  //         .map((a) => {
  //           return {
  //             id:a._id,
  //             articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
  //             unidad: a.articulos[0].unidad,
  //             colores: a.colores[0].name,
  //             cantidad: a.cantidad,
  //             idArticulo: a.articulos[0]._id,
  //             idColor: a.colores[0]._id,
  //             precio:0,
  //             descuento:0
  //           };
  //         }).filter(function (el) {
  //           return el != null;
  //         });

  //       let t = arrayTabla.length
  //         let arrayPrecio = arrayTabla.map((a)=>{
  //           axios.get(`${URL_ARTICULOS_PEDIDO}/precio/${idPDFOC}/${a.idArticulo}/${a.idColor}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }).then((resp)=>{
  //             t = t - 1
  //           let precio = resp.data[0].precio
  //           let descuento = resp.data[0].descuento
  //           let idArticulo = resp.data[0].articulos[0]._id
  //           let idColor = resp.data[0].colores[0]._id

  //          let arrayP =  arrayTabla.map((i) => {
  //             if (i.idArticulo == idArticulo && i.idColor == idColor) {
  //               i.precio = precio
  //               i.descuento = descuento
  //             }
  //             return i;
  //           })

  //           if(t==0){
  //             let dataFinal = Object.values(arrayP);
  //             let cantidadRollos = dataFinal.length

  //             let mts = dataFinal.filter(e=>e.unidad == "Metros")
  //             let totalMts = mts.map((c) => parseFloat(c.cantidad));
  //             let TM = totalMts.reduce((t, total, index) => t + total, 0);
  //             setTotalMetrosEdit(TM);
  //             let KG = dataFinal.filter(e=>e.unidad == "Kilos")
  //             let totalKG = KG.map((c) => parseFloat(c.cantidad));
  //             let TK = totalKG.reduce((t, total, index) => t + total, 0);
  //             setTotalKilosEdit(TK);

  //             let total_importe = 0
  //             let totalParcial = 0
  //             let totalDesc = 0
  //             const data = dataFinal.map((a) => {
  //               if (
  //                 (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
  //                 (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
  //                   (activo == 0 ||
  //                     activo == a.is_active)&&
  //                     (selectedCliente == 0 ||
  //                       selectedCliente == a.idClientes)
  //               )
  //               {
  //                  totalParcial = a.cantidad * a.precio;
  //                  totalDesc = totalParcial - (totalParcial * a.descuento) / 100
  //                  total_importe = total_importe + totalDesc
  //                 return [
  //                   a.articulos,
  //                   a.unidad,
  //                   a.colores,
  //                   new Intl.NumberFormat("en-US").format(a.cantidad),
  //                   "$" + new Intl.NumberFormat("en-US").format(a.precio),
  //                   new Intl.NumberFormat("en-US").format(a.descuento) + "%",
  //                   "$" + new Intl.NumberFormat("en-US").format(totalDesc)
  //                  ];
  //               }
  //             });
  //             const dataPDF = Object.values(data);
  //             const dataPDFLimpia = dataPDF.filter(function (el) {
  //               return el != null;
  //             });
  //             const doc = new jsPDF("landscape");
  //             var img = new Image();
  //             img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //             doc.addImage(img, "png", 230, 30, 45, 15);
  //             var img2 = new Image();
  //             img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //             doc.addImage(img2, "png", 140, 15, 25, 25);
  //             doc.text(`Surtido # ${numero}`, 20, 20);
  //             doc.setFontSize(12)
  //             doc.text(`Fecha ${fecha}`, 20, 25);
  //             doc.text(`Cliente ${clientes}`, 20, 30);
  //             doc.text(`Metros ${new Intl.NumberFormat("en-US").format(TM)} / Kilos ${new Intl.NumberFormat("en-US").format(TK)} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`, 20, 35)
  //             doc.autoTable({
  //               head: [["Articulo","Unidad","Color", "Cantidad", "Precio", "Descuento", "Total"]],
  //               body: dataPDFLimpia,
  //               startY: 45,
  //               foot: [
  //                 [
  //                   "",
  //                   "",
  //                   "",
  //                   "",
  //                   "",
  //                   "Total",
  //                   "$" + new Intl.NumberFormat("en-US").format(total_importe)
  //                 ],
  //               ],
  //               showFoot: "lastPage",
  //             });
  //             doc.save(`Surtido-Desgloce-${numero}.pdf`);
  //           }

  //         })
  //       }
  //       )

  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //   }

  function editSurtidos(event) {
    event.preventDefault();
    const URL_SURTIDO_EDIT = `${process.env.REACT_APP_URL_SURTIDOS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_SURTIDO_EDIT,
          {
            fecha,
            pedimento,
            partida,
            observaciones,
            aduana,
            fecha_aduana,
            pais_origen,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Surtido",
            detalle: `${numero_surtido} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Vendedor", field: "colaborador", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaborador.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaborador.includes(selectedColaborador)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditArticulos(idC, numero_surtido, idOrdenSurtido) {
    axios
      .get(`${URL_ARTICULOS_OC}/ordenesSurtido/${idOrdenSurtido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFields([
      {
        id: uuidv4(),
        articulosOC: "",
        articulos: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        tono: "",
      },
    ]);
    setIdEdit(idC);
    setNumeroSurtido(numero_surtido);

    axios
      .get(`${URL_INVENTARIOS}/surtidos/${idC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        let arrayTabla = allArticulos
          .map((a) => {
            return {
              id: a._id,
              articulosOC: a.articulosOC[0]._id,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              cantidad: a.cantidad,
              tono: a.tono,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosSurtido(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleEditArticulos();
  }

  function BuscaArticulo(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        let unidadArticulo = a.articulos[0].unidad;
        let color = a.colores[0]._id;
        handleChangeInputArticulo(
          id,
          articulosOC,
          idArticulo,
          unidadArticulo,
          color
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    articulosOC,
    idArticulo,
    unidadArticulo,
    color
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        let unidadArticulo = a.articulos[0].unidad;
        let color = a.colores[0]._id;
        handleChangeInputArticuloExistentes(
          id,
          articulosOC,
          idArticulo,
          unidadArticulo,
          color
        );
      }
    });
  }

  const handleChangeInputArticuloExistentes = (
    id,
    articulosOC,
    idArticulo,
    unidadArticulo,
    color
  ) => {
    const newArticulosSurtido = articulosSurtido.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
      }
      return i;
    });
    setArticulosSurtido(newArticulosSurtido);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mtsExistentes = articulosSurtido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosSurtido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosSurtido = articulosSurtido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosSurtido(newArticulosSurtido);
    ActualizaTotales();
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOC: "",
        articulos: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        tono: "",
      },
    ]);
  };

  const saveSurtido = async (event) => {
    event.preventDefault();
    const URL_SURTIDO_EDIT = `${process.env.REACT_APP_URL_SURTIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_SURTIDO_EDIT,
          {
            total_kilos: total_kilos_edit,
            total_metros: total_metros_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            axios.post(
              URL_INVENTARIOS,
              {
                surtidos: idEdit,
                articulos: a.articulos,
                articulosOC: a.articulosOC,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                tono: a.tono,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          });
          articulosSurtido.map((a) => {
            axios.patch(
              `${URL_INVENTARIOS}/${a.id}`,
              {
                articulos: a.articulos,
                articulosOC: a.articulosOC,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                tono: a.tono,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          });
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Surtido",
              detalle: `${numero_surtido}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // function desarrollo(){
  //   comments.map((a)=>{
  //     axios
  //       .patch(
  //         `${URL_SURTIDOS}/${a._id}`,
  //         {
  //           clientes: a.idClientes,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //           },
  //         }
  //       )      
  //       .then(() => {
  //         Swal.fire("Good job!", "Enviado con exito", "success");
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //           footer: `${error.response.data}`,
  //         });
  //         console.log(error);
  //       });
  //   })
  // }

//   function desarrollo(){
//   axios
//   .get(`${URL_SURTIDOS}Desarrollo`, {
//     headers: {
//       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//     },
//   })
//   .then((response) => {
// console.log("listo", response.data)
//   })
//   .catch((err) => {
//     console.log(err);
//   });
// }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Surtidos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Surtido</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Colaboradores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                  })}
              </Input>
              </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                    <td>{a.pedidos}</td>
                    <td>{a.colaborador}</td>
                    <td>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={(e) =>
                          PDFOC(
                            a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.plazo,
                                  a.prefijo,
                                  a.empresas
                          )
                        }
                      >
                        <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={(e) =>
                          ExcelOC(
                            a.fecha,
                            a.idClientes,
                            a.clientes,
                            a.observaciones,
                            a.numero,
                            a.prefijo,
                            a._id,
                            a.user,
                            a.pedidos
                          )
                        }
                      >
                        <i class="far fa-file-excel"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Surtido # {numero_surtido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pedimento</Label>
              <Input
                type="text"
                placeholder="Pedimento"
                value={pedimento}
                required
                onChange={(e) => {
                  setPedimento(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Factura Cliente</Label>
              <Input
                type="text"
                placeholder="Factura Cliente"
                value={factura_cliente}
                required
                onChange={(e) => {
                  setFacturaCliente(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Partida</Label>
              <Input
                type="text"
                placeholder="Partida"
                value={partida}
                required
                onChange={(e) => {
                  setPartida(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Aduana</Label>
              <Input
                type="text"
                placeholder="Aduana"
                value={aduana}
                required
                onChange={(e) => {
                  setAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Aduana</Label>
              <Input
                type="date"
                placeholder="Fecha Aduana"
                value={fecha_aduana}
                required
                onChange={(e) => {
                  setFechaAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editSurtidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Surtido # {numero_surtido}</h4>
          <h4>
            TOTALES {total_kilos_edit} kg. / {total_metros_edit} mts.
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Tono</Label>
            </Col>
          </Row>

          {/* Articulos Existentes*/}

          {articulosSurtido.map((ac) => (
            <div key={ac.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={ac.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ac.id, event);
                    }}
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.articulos[0].codigo} / {a.articulos[0].nombre} /{" "}
                            {a.colores[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={ac.unidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    placeholder="Cantidad"
                    value={ac.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ac.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="tono"
                    type="text"
                    placeholder="Tono"
                    value={ac.tono}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentes(ac.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].codigo > b.articulos[0].codigo ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.articulos[0].codigo} / {a.articulos[0].nombre} /{" "}
                            {a.colores[0].name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={inputField.unidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="tono"
                    type="text"
                    placeholder="Tono"
                    value={inputField.tono}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(total_kilos_edit)} kg. /{" "}
                {new Intl.NumberFormat("en-US").format(total_metros_edit)} mts.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={saveSurtido}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoSurtidos;
