import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function ListadoDisponible() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;

  const [inventarios, setInventarios] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedComposicion, setSelectedComposicion] = useState("");
  const [articulosComposicion, setArticulosComposicion] = useState([]);

  const [unidad, setUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [articulosCodigos, setArticulosCodigos] = useState([]);
  const [articulosNombre, setArticulosNombre] = useState([]);
  const [selectedCodigo, setSelectedCodigo] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(`${URL_INVENTARIOS}Disponible`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setComments(allDisponible);
        setInventarios(allDisponible);

        var items = allDisponible.map((a) => {
          return a.composicion;
        });

        const unicos = [];
        for (var i = 0; i < items.length; i++) {
          const elemento = items[i];

          if (!unicos.includes(items[i])) {
            unicos.push(elemento);
          }
        }
        setArticulosComposicion(unicos);

        let agrupadoCodigos = allDisponible.reduce(function (groups, item) {
          const val = item["codigo"]
          groups[val] = groups[val] || {
            codigo: item.codigo,
            cantidad:0
          };
          groups[val].codigo = item.codigo;
          groups[val].idArticulo = item.idArticulo;
          groups[val].cantidad += item.cantidad;
          return groups;
        }, []);
        let dataFinalCodigos = Object.values(agrupadoCodigos);
        setArticulosCodigos(dataFinalCodigos);

        let agrupadoNombres = allDisponible.reduce(function (groups, item) {
          const val = item["nombre"]
          groups[val] = groups[val] || {
            nombre: item.nombre,
            idArticulo:""
          };
          groups[val].nombre = item.nombre;
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);
        let dataFinalNombres = Object.values(agrupadoNombres);
        setArticulosNombre(dataFinalNombres);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposTelas = response.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaInventarios = comments.sort((a, b) =>
    a.codigo > b.codigo ? 1 : -1
  );

  function PDFTabla() {
    let total_inventario = 0;
    let total_pedido = 0;
    let total_apartado = 0;
    let total_disponible = 0;

    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.tono.toLowerCase().includes(search.toLowerCase()) ||
          comment.ancho.toString().includes(search) ||
          comment.inventario.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.disponible.toString().includes(search)||
          comment.apartado.toString().includes(search)
      );
    }
    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "pedido" &&
      sorting.field != "disponible" &&
      sorting.field != "apartado"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
        sorting.field == "pedido" ||
        sorting.field == "disponible" ||
        sorting.field == "apartado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
        sorting.field == "pedido" ||
        sorting.field == "disponible" ||
        sorting.field == "apartado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    const data = computedComments
      .map((a) => {
          total_inventario =
            parseFloat(total_inventario) + parseFloat(a.inventario);
          total_pedido = parseFloat(total_pedido) + parseFloat(a.pedido);
          total_apartado = parseFloat(total_apartado) + parseFloat(a.apartado);
          total_disponible =
            parseFloat(total_disponible) + parseFloat(a.disponible);
          return [
            a.codigo,
            a.nombre,
            a.colores,
            a.tono,
            a.ancho,
            a.composicion,
            a.unidad,
            a.tiposTelas,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.apartado),
            new Intl.NumberFormat("en-US").format(a.disponible),
          ];
        }
      )
      
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      // styles: {
      //     valign: 'center'
      // },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      footStyles: {
        valign: "middle",
        halign: "right",
      },
      columnStyles: {
        0: { minCellWidth: 20 },
        1: { minCellWidth: 20 },
        2: { minCellWidth: 18 },
        3: { minCellWidth: 20, halign: "center" },
        4: { minCellWidth: 10, fontSize: 8 },
        5: {
          minCellWidth: 20,
          // valign: 'center'
        },
        6: { minCellWidth: 20 },
        7: {
          minCellWidth: 25,
          halign: "right",
          // valign: 'center'
        },
        8: {
          minCellWidth: 25,
          halign: "right",
          // valign: 'center'
        },
        9: {
          minCellWidth: 25,
          halign: "right",
          // valign: 'center'
        },
        10: {
          minCellWidth: 25,
          halign: "right",
          // valign: 'center'
        },
        11: {
          minCellWidth: 25,
          halign: "right",
          // valign: 'center'
        },
      },
      head: [
        [
          "Codigo",
          "Nombre",
          "Color",
          "Tono",
          "Ancho",
          "Composicion",
          "Unidad",
          "Tipo de Tela",
          "Inventario",
          "Pedido",
          "Apartado",
          "Disponible",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_apartado),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
      showFoot: "lastPage",
    });
    // doc.save(`Disponible.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
        comment.colores.toLowerCase().includes(search.toLowerCase()) ||
        comment.inventario.toString().includes(search) ||
        comment.pedido.toString().includes(search) ||
        comment.disponible.toString().includes(search)
    )
    .map((a) => {
      if (
        (selectedTiposTelasFiltro == 0 ||
          selectedTiposTelasFiltro == a.idTiposTelas) &&
        (selectedColor == 0 || selectedColor == a.idColores) &&
        (selectedCodigo == 0 || selectedCodigo == a.idArticulo) &&
        (selectedNombre == 0 || selectedNombre == a.idArticulo) &&
        (unidad == 0 || unidad == a.unidad) &&
        (selectedComposicion == 0 || selectedComposicion == a.composicion)
      ) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          Color: a.colores,
          Tono: a.tono,
          Ancho: a.ancho,
          Composicion: a.composicion,
          Unidad: a.unidad,
          Tipo: a.tiposTelas,
          Inventario: a.inventario,
          Pedido: a.pedido,
          Disponible: a.disponible,
      }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDisponible";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDisponible",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Color",
          "Tono",
          "Ancho",
          "Composicion",
          "Unidad",
          "Tipo",
          "Inventario",
          "Pedido",
          "Disponible",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Color",
          "Tono",
          "Ancho",
          "Composicion",
          "Unidad",
          "Tipo",
          "Inventario",
          "Pedido",
          "Disponible",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_inventario = 0;
    let total_pedido = 0;
    let total_apartado = 0;
    let total_disponible = 0;

    const data = comments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
        comment.colores.toLowerCase().includes(search.toLowerCase()) ||
        comment.inventario.toString().includes(search) ||
        comment.pedido.toString().includes(search) ||
        comment.disponible.toString().includes(search)
    )
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
          (selectedTiposTelasFiltro == 0 ||
            selectedTiposTelasFiltro == a.idTiposTelas) &&
          (selectedColor == 0 || selectedColor == a.idColores) &&
          (selectedCodigo == 0 || selectedCodigo == a.idArticulo) &&
          (selectedNombre == 0 || selectedNombre == a.idArticulo) &&
          (unidad == 0 || unidad == a.unidad) &&
          (selectedComposicion == 0 || selectedComposicion == a.composicion) 
        ) {
          total_inventario = total_inventario + a.inventario;
          total_pedido = total_pedido + a.pedido;
          total_apartado = total_apartado + a.apartado;
          total_disponible = total_disponible + a.disponible;
          return [
            a.codigo,
            a.nombre,
            a.colores,
            a.composicion,
            a.unidad,
            a.tiposTelas,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.apartado),
            new Intl.NumberFormat("en-US").format(a.disponible),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Color",
          "Tono",
          "Ancho",
          "Composicion",
          "Unidad",
          "Tipo de Tela",
          "Inventario",
          "Pedido",
          "Apartado",
          "Disponible",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_apartado),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Disponible",
          email: mailTo,
          fileName: "ListadoDisponible.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Color", field: "colores", sortable: true },
    { name: "Tono", field: "tono", sortable: true },
    { name: "Ancho", field: "ancho", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Tipo de Tela", field: "tiposTelas", sortable: true },
    { name: "Inventario", field: "inventario", sortable: true },
    { name: "Pendiente Surtir", field: "pedido", sortable: true },
    { name: "Apartado", field: "apartado", sortable: true },
    { name: "Disponible", field: "disponible", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.tiposTelas.toLowerCase().includes(search.toLowerCase()) ||
          comment.colores.toLowerCase().includes(search.toLowerCase()) ||
          comment.tono.toLowerCase().includes(search.toLowerCase()) ||
          comment.ancho.toString().includes(search) ||
          comment.inventario.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.disponible.toString().includes(search)||
          comment.apartado.toString().includes(search)
      );
    }
    if (selectedTiposTelasFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idTiposTelas.includes(selectedTiposTelasFiltro)
      );
    }
    if (selectedColor) {
      computedComments = computedComments.filter((e) =>
        e.idColores.includes(selectedColor)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedNombre)
      );
    }
    if (unidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(unidad)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.composicion.includes(selectedComposicion)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "pedido" &&
      sorting.field != "disponible" &&
      sorting.field != "apartado"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
        sorting.field == "pedido" ||
        sorting.field == "disponible" ||
        sorting.field == "apartado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
        sorting.field == "pedido" ||
        sorting.field == "disponible" ||
        sorting.field == "apartado")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedTiposTelasFiltro,
    selectedColor,
    selectedCodigo,
    selectedNombre,
    unidad,
    selectedComposicion,
  ]);

  function limpiarSearch() {
    setSearch("");
    setSelectedArticulo("");
    setSelectedCodigo("")
    setSelectedNombre("")
    setSelectedColor("");
    setSelectedComposicion("");
    setSelectedTiposTelasFiltro("");
    setUnidad("");
  }

  let totalDisponible = 0;
  let totalPedido = 0;
  let totalInventario = 0;
  let totalApartado = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_disponible ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Disponible</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-md-1 flex-row-reverse">
              <Button
                size="sm"
                className="btn"
                color="info"
                onClick={limpiarSearch}
              >
                Limpiar
              </Button>
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
            <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosCodigos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosNombre
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColor}
                  onChange={(e) => {
                    setSelectedColor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedComposicion}
                  onChange={(e) => {
                    setSelectedComposicion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulosComposicion
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a}>{a}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  placeholder="Unidad"
                  value={unidad}
                  required
                  onChange={(e) => {
                    setUnidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Metros">Metros</option>
                  <option value="Kilos">Kilos</option>
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedTiposTelasFiltro}
                  onChange={(e) => {
                    setSelectedTiposTelasFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {tipos_telas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                totalInventario = totalInventario + a.inventario;
                totalPedido = totalPedido + a.pedido;
                totalDisponible = totalDisponible + a.disponible;
                totalApartado = totalApartado + a.apartado
                {
                    return (
                      <tr>
                        <td>{a.codigo}</td>
                        <td>{a.nombre}</td>
                        <td>{a.colores}</td>
                        <td>{a.tono}</td>
                        <td>{a.ancho}</td>
                        <td>{a.composicion}</td>
                        <td>{a.unidad}</td>
                        <td>{a.tiposTelas} </td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(a.inventario)}
                        </td>
                        <td>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(a.apartado)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US").format(a.disponible)}
                        </td>
                      </tr>
                    );

                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalInventario)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPedido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalApartado)}
                </td>      
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalDisponible)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default ListadoDisponible;
