import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesSinCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_DEVOLUCIONES = process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  const URL_DEVOLUCIONES_ACUMULADOS =
    process.env.REACT_APP_URL_DEVOLUCIONES_ACUMULADOS;

  const [surtidos, setSurtidos] = useState([]);
  const [selectedSurtido, setSelectedSurtido] = useState("");

  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [fecha, setFecha] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [idColaboradores, setIdColaboradores] = useState("");

  const [pedido, setPedido] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [articulosDevolucion, setArticulosDevolucion] = useState([]);
  const [fecha_surtido, setFechaSurtido] = useState("");
  const [clientePedido, setClientePedido] = useState("");
  const [total_general, setTotalGeneral] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);
  const [rollosTotales, setRollosTotales] = useState(0);

  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();

  const [validaBoton, setValidaBoton] = useState(true);

  useMemo(() => {
    axios
      .get(`${URL_SURTIDOS}ClienteDev/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSurtidos = res.data;
        setSurtidos(allSurtidos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCliente]);

  function calculaDevolucion() {
    const newArticulosPedido = articulosPedido.map((AP) => {
      cantidadAgrupado.map((c) => {
        if (
          AP.articulos[0]._id == c.idArticulo &&
          AP.colores[0]._id == c.color
        ) {
          AP.surtido = parseFloat(AP.surtido) - parseFloat(c.cantidad);
          AP.pendiente_surtir =
            parseFloat(AP.pendiente_surtir) + parseFloat(c.cantidad);
        }
        return AP;
      });
    });
    setArticulosPedido(newArticulosPedido);
  }

  const saveDevolucion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    calculaDevolucion();

    let totalRollos = articulosDevolucion.length + cantidadAgrupado.length;
    let sal 
    let subSal
    let subIva
    let subAplicada
    if (impuestos == 16 && total_general_pedido == 0){
      subSal = 0
      sal = 0
      subIva = 0
      subAplicada = "Si"
    }else{
      sal = total_general.toFixed(2)
      subSal = subTotal.toFixed(2)
      subIva = iva.toFixed(2)
      subAplicada = "No"
    }
    // + articulosPedido.length
    try {
      await axios
        .post(
          URL_DEVOLUCIONES,
          {
            fecha,
            surtidos: selectedSurtido,
            user: user.id,
            observaciones,
            aplicada: "No",
            total_cantidad: total_kilos + total_metros,
            clientes: idCliente,
            subTotal:subTotal.toFixed(2),
            iva: iva.toFixed(2),
            total_general:total_general.toFixed(2),
            impuestos,
            colaboradores:idColaboradores,
            comisionPagada:"No"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          articulosDevolucion.map((a) => {
            if (a.select) {
              axios
                .patch(
                  `${URL_INVENTARIOS}/devolucion/${a._id}`,
                  {
                    devoluciones: data.data._id,
                    vendido: "No",
                    articulos:a.idArticulo,
                    colores:a.color,
                    tono:a.tono,
                    precio: a.precio,
                    surtidos: selectedSurtido,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `Surtido ${selectedSurtido}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Surtido ${selectedSurtido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });



          // Pegamos a Devoluciones Acumulado

          cantidadAgrupado.map((w) => {
            axios
              .post(
                `${URL_DEVOLUCIONES_ACUMULADOS}`,
                {
                  devoluciones: data.data._id,
                  articulos: w.idArticulo,
                  articulosPedido: w.articulosPedido,
                  colores: w.color,
                  cantidad: w.cantidad,
                  pedidos: pedido,
                  fecha,
                  clientes: idCliente,
                  surtidos: selectedSurtido,
                  tono: w.tono,
                  venta: w.venta,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `Surtido ${selectedSurtido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });

          // Terminamos de post a devoluciones acumulado
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  function ActualizaTotales() {
    let mts = articulosDevolucion.filter((e) => e.unidadArticulo == "Metros" && e.select);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = articulosDevolucion.filter((e) => e.unidadArticulo == "Kilos" && e.select);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let td = articulosDevolucion.filter((e) => e.select);
    let totalDinero = td.map((c) => parseFloat(c.venta));
    let dinero = totalDinero.reduce((t, total, index) => t + total, 0);
    let dineroF = parseFloat(dinero.toFixed(2))

    setSubTotal(dineroF);
    if (impuestos != 0) {
      setIva((impuestos * dineroF) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * dineroF) / 100 + dineroF;

    setTotalGeneral(totTemp);

    let agrupado = td.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["color"] + item["tono"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        color: item.color,
        tono: item.tono,
        cantidad: 0,
        venta: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].venta += item.venta;
      groups[val].id = item.id;
      groups[val].idInventario = item.idInventario;
      groups[val].articulos = item.articulos;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidadArticulo = item.unidadArticulo;
      groups[val].color = item.color;
      groups[val].tono = item.tono;
      groups[val].articulosPedido = item.articulosPedido;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

    let tr = 0;
    articulosDevolucion.map((a) => {
      if (a.select) {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  function jalaSurtido(e) {
    setSelectedSurtido(e);

    axios
      .get(`${URL_INVENTARIOS}/surtidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosDevolucion = res.data;
        setArticulosDevolucion(allArticulosDevolucion);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        setArticulosPedido(allArticulosPedido);
      })
      .catch((err) => {
        console.log(err);
      });

    surtidos.map((a) => {
      if (a._id == e) {
        setFechaSurtido(a.fecha);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.clientes[0].razon_social);
        setIdCliente(a.clientes[0]._id);
        setIdColaboradores(a.colaboradores[0]._id);
        setPedido(a.pedidos[0]._id);
        setImpuestos(a.impuestos);
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Devolucion</h3>
          <Form>
            <Row>
            <Col md={3}>
                <Label>Cliente</Label>
                <Input
                  type="select"
                  placeholder="Cliente"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Surtido</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSurtido}
                  required
                  onChange={(e) => jalaSurtido(e.target.value)}
                >
                  <option value="0">Selecciona un Surtido</option>
                  {surtidos
                    .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          # {a.idSurtido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Surtido</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_surtido}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaSurtido(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Cliente</Label>
                <Input
                  type="text"
                  placeholder="Cliente"
                  value={cliente}
                  required
                  disabled
                  onChange={(e) => {
                    setCliente(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">tono</Label>
              </Col>
            </Row>

            {articulosDevolucion.map((inputField) => (
              <div key={inputField._id}>
              <Row> <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventario"
                      type="string"
                      value={inputField._id}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articulo"
                      type="string"
                      value={inputField.articulo}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidadArticulo}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="tono"
                      type="string"
                      value={inputField.tono}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setArticulosDevolucion(
                                  articulosDevolucion.map(data => {
                                    if(inputField._id == data._id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                ActualizaTotales()
                              }}
                              checked={inputField.select}
                            />
                        </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
          </Form>
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveDevolucion}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoDevoluciones"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesSinCreate;
