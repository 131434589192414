import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardVentas() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;


  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [info, setInfo] = useState([]);
  const [graficasArticulos, setGraficasArticulos] = useState([]);
  const [graficasTiposTelas, setGraficasTiposTelas] = useState([]);
  const [graficasClientes, setGraficasClientes] = useState([]);
  const [graficasVendedores, setGraficasVendedores] = useState([]);
  const [graficasMes, setGraficasMes] = useState([]);

  const [valorVenta, setValorVenta] = useState(0);
  const [cantidadVenta, setCantidadVenta] = useState(0);

  const [clientes, setClientes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [tiposTelas, setTiposTelas] = useState([]);

  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedTipoTela, setSelectedTipoTela] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Ventas/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setInfo(array)

      let unicosClien = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCliente === value.idCliente)
      );
      setClientes(unicosClien);

      let unicosVen = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idVendedor === value.idVendedor)
      );
      setVendedores(unicosVen);

      let unicosArt = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArticulo === value.idArticulo)
      );
      setArticulos(unicosArt);

      let unicosTipo = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idTiposTelas === value.idTiposTelas)
      );
      setTiposTelas(unicosTipo);

      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

  
},[])

function BuscaInfo(){
  setInfo([])
  setSelectedCliente("")
  setSelectedVendedor("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}Ventas/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setInfo(array)

      let unicosClien = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCliente === value.idCliente)
      );
      setClientes(unicosClien);

      let unicosVen = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idVendedor === value.idVendedor)
      );
      setVendedores(unicosVen);

      let unicosArt = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArticulo === value.idArticulo)
      );
      setArticulos(unicosArt);

      let unicosTipo = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idTiposTelas === value.idTiposTelas)
      );
      setTiposTelas(unicosTipo);
    
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}

  useMemo(() => {
      let temp = info
          if (selectedCliente) {
            temp = temp.filter((e) =>
              e.idCliente.includes(selectedCliente)
            );
          }
          if (selectedVendedor) {
            temp = temp.filter((e) =>
              e.idVendedor.includes(selectedVendedor)
            );
          }
          if (selectedArticulo) {
            temp = temp.filter((e) =>
              e.idArticulo.includes(selectedArticulo)
            );
          }
          if (selectedTipoTela) {
            temp = temp.filter((e) =>
              e.idTiposTelas.includes(selectedTipoTela)
            );
          }


        let agrupadoVendedor = temp.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasVendedores(otraVendedor);

        let agrupadoMes = temp.reduce(function (groups, item) {
          const val = item["Mes"];
          groups[val] = groups[val] || {
            Mes: item.Mes,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Mes = item.Mes;
          return groups;
        }, []);

        let otraMes = Object.values(agrupadoMes)

        setGraficasMes(otraMes);

        let agrupadoArticulo = temp.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Articulo = item.Articulo;
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);
  
        let otraArticulo = Object.values(agrupadoArticulo).sort((a, b) =>
         a.Importe < b.Importe ? 1 : -1
        );
  
        setGraficasArticulos(otraArticulo);

        let agrupadoTiposTelas = temp.reduce(function (groups, item) {
          const val = item["idTiposTelas"];
          groups[val] = groups[val] || {
            idTiposTelas: item.idTiposTelas,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].TiposTelas = item.TiposTelas;
          groups[val].idTiposTelas = item.idTiposTelas;
          return groups;
        }, []);
  
        let otraTiposTelas = Object.values(agrupadoTiposTelas).sort((a, b) =>
         a.Importe < b.Importe ? 1 : -1
        );
  
        setGraficasTiposTelas(otraTiposTelas);


        let agrupadoCliente = temp.reduce(function (groups, item) {
          const val = item["idCliente"];
          groups[val] = groups[val] || {
            idCliente: item.idCliente,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Cliente = item.Cliente;
          groups[val].idCliente = item.idCliente;
          return groups;
        }, []);
  
        let otraCliente = Object.values(agrupadoCliente).sort((a, b) =>
         a.Importe < b.Importe ? 1 : -1
        );
  
        setGraficasClientes(otraCliente);


        let TVal = temp.map((c) => parseFloat(c.Importe));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorVenta(TV);

        let TCan = temp.map((c) => parseFloat(c.Cantidad));
        let TC = TCan.reduce((t, total, index) => t + total, 0);
        setCantidadVenta(TC);

  }, [info, selectedCliente,  selectedVendedor, selectedArticulo, selectedTipoTela]);


  useLayoutEffect(() => {
       
 // Grafica Articulos
 let rootArticulos = am5.Root.new("chartdivArticulos");
 
 rootArticulos.setThemes([am5themes_Animated.new(rootArticulos)]);
 
 let chartArticulos = rootArticulos.container.children.push(
   am5percent.PieChart.new(rootArticulos, {
     endAngle: 270,
     layout: rootArticulos.verticalLayout,
     tooltip: am5.Tooltip.new(rootArticulos, {
       labelText: "{Articulo} {value}",
     }),
   })
 );
 
 let seriesArticulos = chartArticulos.series.push(
   am5percent.PieSeries.new(rootArticulos, {
     valueField: "Importe",
     categoryField: "Articulo",
     endAngle: 270,
   })
 );
 
 seriesArticulos.states.create("hidden", {
   endAngle: -90,
 });
 
 chartArticulos.children.unshift(
   am5.Label.new(rootArticulos, {
     text: "Articulos",
     fontSize: 25,
     fontWeight: "500",
     textAlign: "center",
     x: am5.percent(50),
     centerX: am5.percent(50),
     paddingTop: 0,
     paddingBottom: 0,
   })
 );
 
 seriesArticulos.labels.template.set("forceHidden", true);
 seriesArticulos.ticks.template.setAll({
   visible: false,
   stroke: am5.color("rgb(252, 252, 252)"),
 });
 seriesArticulos.data.setAll(graficasArticulos);
 seriesArticulos.appear(1000, 100);
 
 // let legendArticulos = chartArticulos.children.push(
 //   am5.Legend.new(rootArticulos, {
 //     nameField: "articulo",
 //   })
 // );
 
 // legendArticulos.data.setAll(seriesArticulos.dataItems);
 
// Grafica Clientes
let rootClientes = am5.Root.new("chartdivClientes");
 
rootClientes.setThemes([am5themes_Animated.new(rootClientes)]);

let chartClientes = rootClientes.container.children.push(
  am5percent.PieChart.new(rootClientes, {
    endAngle: 270,
    layout: rootClientes.verticalLayout,
    tooltip: am5.Tooltip.new(rootClientes, {
      labelText: "{Cliente} ${value}",
    }),
  })
);

let seriesClientes = chartClientes.series.push(
  am5percent.PieSeries.new(rootClientes, {
    valueField: "Importe",
    categoryField: "Cliente",
    endAngle: 270,
  })
);

seriesClientes.states.create("hidden", {
  endAngle: -90,
});

chartClientes.children.unshift(
  am5.Label.new(rootClientes, {
    text: "Clientes",
    fontSize: 25,
    fontWeight: "500",
    textAlign: "center",
    x: am5.percent(50),
    centerX: am5.percent(50),
    paddingTop: 0,
    paddingBottom: 0,
  })
);

seriesClientes.labels.template.set("forceHidden", true);
seriesClientes.ticks.template.setAll({
  visible: false,
  stroke: am5.color("rgb(252, 252, 252)"),
});
seriesClientes.data.setAll(graficasClientes);
seriesClientes.appear(1000, 100);

// let legendClientes = chartClientes.children.push(
//   am5.Legend.new(rootClientes, {
//     nameField: "articulo",
//   })
// );

// legendClientes.data.setAll(seriesClientes.dataItems);

     // Venta Mensual

     let rootMes = am5.Root.new("chartdivMes");

     rootMes.setThemes([am5themes_Animated.new(rootMes)]);
 
     let chartMes = rootMes.container.children.push(
       am5xy.XYChart.new(rootMes, {
         panY: false,
         layout: rootMes.verticalLayout,
       })
     );
 
     let dataMes = graficasMes;
 
     // Create Y-axis
     let yAxisMes = chartMes.yAxes.push(
       am5xy.ValueAxis.new(rootMes, {
        min:0,
         renderer: am5xy.AxisRendererY.new(rootMes, {}),
       })
     );
 
     // Create X-Axis
     let xAxisMes = chartMes.xAxes.push(
       am5xy.CategoryAxis.new(rootMes, {
         renderer: am5xy.AxisRendererX.new(rootMes, {}),
         categoryField: "Mes",
       })
     );
     xAxisMes.data.setAll(dataMes);
 
     // Create series
     let seriesMes = chartMes.series.push(
       am5xy.ColumnSeries.new(rootMes, {
         name: "Mes",
         xAxis: xAxisMes,
         yAxis: yAxisMes,
         valueYField: "Importe",
         categoryXField: "Mes",
         tooltip: am5.Tooltip.new(rootMes, {
           labelText: "{Mes} ${valueY}",
         }),
       })
     );
     seriesMes.data.setAll(dataMes);
 
     // Add cursor
     chartMes.set("cursor", am5xy.XYCursor.new(rootMes, {}));
 
     let exportingMes = am5plugins_exporting.Exporting.new(rootMes, {
       menu: am5plugins_exporting.ExportingMenu.new(rootMes, {}),
     });
 
     chartMes.children.unshift(
       am5.Label.new(rootMes, {
         text: "Mensual",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );

      
     let rootVendedores = am5.Root.new("chartdivVendedores");

     rootVendedores.setThemes([am5themes_Animated.new(rootVendedores)]);
 
     let chartVendedores = rootVendedores.container.children.push(
       am5percent.PieChart.new(rootVendedores, {
         endAngle: 270,
         layout: rootVendedores.verticalLayout,
         tooltip: am5.Tooltip.new(rootVendedores, {
           labelText: "{Vendedor} ${value}",
         }),
       })
     );
 
     let seriesVendedores = chartVendedores.series.push(
       am5percent.PieSeries.new(rootVendedores, {
         valueField: "Importe",
         categoryField: "Vendedor",
         endAngle: 270,
       })
     );
 
     seriesVendedores.states.create("hidden", {
       endAngle: -90,
     });
 
     chartVendedores.children.unshift(
       am5.Label.new(rootVendedores, {
         text: "Vendedores",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesVendedores.labels.template.set("forceHidden", true);
     seriesVendedores.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesVendedores.data.setAll(graficasVendedores);
     seriesVendedores.appear(1000, 100);
 
     let legendVendedores = chartVendedores.children.push(
       am5.Legend.new(rootVendedores, {
         nameField: "Vendedor",
       })
     );
 
     legendVendedores.data.setAll(seriesVendedores.dataItems);

     // Grafica TiposTelas
 let rootTiposTelas = am5.Root.new("chartdivTiposTelas");
 
 rootTiposTelas.setThemes([am5themes_Animated.new(rootTiposTelas)]);
 
 let chartTiposTelas = rootTiposTelas.container.children.push(
   am5percent.PieChart.new(rootTiposTelas, {
     endAngle: 270,
     layout: rootTiposTelas.verticalLayout,
     tooltip: am5.Tooltip.new(rootTiposTelas, {
       labelText: "{TiposTelas} {value}",
     }),
   })
 );
 
 let seriesTiposTelas = chartTiposTelas.series.push(
   am5percent.PieSeries.new(rootTiposTelas, {
     valueField: "Importe",
     categoryField: "TiposTelas",
     endAngle: 270,
   })
 );
 
 seriesTiposTelas.states.create("hidden", {
   endAngle: -90,
 });
 
 chartTiposTelas.children.unshift(
   am5.Label.new(rootTiposTelas, {
     text: "Tipos Telas",
     fontSize: 25,
     fontWeight: "500",
     textAlign: "center",
     x: am5.percent(50),
     centerX: am5.percent(50),
     paddingTop: 0,
     paddingBottom: 0,
   })
 );
 
 seriesTiposTelas.labels.template.set("forceHidden", true);
 seriesTiposTelas.ticks.template.setAll({
   visible: false,
   stroke: am5.color("rgb(252, 252, 252)"),
 });
 seriesTiposTelas.data.setAll(graficasTiposTelas);
 seriesTiposTelas.appear(1000, 100);
 
 let legendTiposTelas = chartTiposTelas.children.push(
   am5.Legend.new(rootTiposTelas, {
     nameField: "TiposTelas",
   })
 );
 
 legendTiposTelas.data.setAll(seriesTiposTelas.dataItems);


    return () => {
      rootVendedores.dispose();
      rootMes.dispose();
      rootArticulos.dispose();
      rootTiposTelas.dispose();
      rootClientes.dispose();
    };
  }, [ graficasMes, graficasVendedores, graficasArticulos, graficasClientes, graficasTiposTelas]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/MenuReportes"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Ventas</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>

           

            <Col md={2}>
              <Label className="mr-sm-2">Clientes</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.Cliente > b.Cliente ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idCliente}>{a.Cliente}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Vendedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedVendedor}
                required
                onChange={(e) => {
                  setSelectedVendedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {vendedores
                  .sort((a, b) => (a.Vendedor > b.Vendedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idVendedor}>{a.Vendedor}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                required
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.Articulo > b.Articulo ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArticulo}>{a.Articulo}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Tipo Telas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedTipoTela}
                required
                onChange={(e) => {
                  setSelectedTipoTela(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {tiposTelas
                  .sort((a, b) => (a.TiposTelas > b.TiposTelas ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idTiposTelas}>{a.TiposTelas}</option>;
                  })}
              </Input>
            </Col>
        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Cantidad
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Venta $
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdivClientes" style={{ width: "40%", height: "500px" }}></div>
          <div
            id="chartdivVendedores"
            style={{ width: "40%", height: "500px" }}
          ></div>

        </div>


      <br />
      <br />
       
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
         
          <div
            id="chartdivTiposTelas"
            style={{ width: "40%", height: "500px" }}
          ></div>
          <div id="chartdivArticulos" style={{ width: "40%", height: "500px" }}></div>

        </div>

     

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <div
            id="chartdivMes"
            style={{ width: "90%", height: "500px" }}
          ></div>
        </div>



      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardVentas;
