import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function AnalisisArticulosPedidos() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");




  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");



    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50


  useEffect(() => {
       axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }, []);

  function BuscaArticulo(event) {
    setSelectedArticulo(event.target.value)
    setComments([])


  // Jala Movimientos
  axios
  .get(`${URL_ARTICULOS_PEDIDO}Articulo/${event.target.value}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allMovs = response.data
    setComments(allMovs);
  })
  .catch((err) => {
    console.log(err);
  })
  }




  const headers = [
    { name: "Pedido", field: "pedido", sortable: false },
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Autorizado", field: "autorizado", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Surtido", field: "surtido", sortable: true },
    { name: "Pend Surtir", field: "pendiente_surtir", sortable: true },
];



  const commentsData = useMemo(() => {
    let computedComments = comments
  
    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.pedido.toString().includes(search)  ||
                comment.activo.toLowerCase().includes(search.toLowerCase()) ||
                comment.autorizado.toLowerCase().includes(search.toLowerCase()) ||
                comment.cantidad.toString().includes(search) ||
                comment.surtido.toString().includes(search) ||
                comment.pendiente_surtir.toString().includes(search) 
        );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "cantidad" && sorting.field != "surtido" && sorting.field != "pendiente_surtir" && sorting.field != "pedido") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "cantidad" || sorting.field == "surtido" || sorting.field == "pendiente_surtir" || sorting.field != "pedido" )) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "cantidad" || sorting.field == "surtido" || sorting.field == "pendiente_surtir" || sorting.field != "pedido" )) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting]);



  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
         
          </Row>
         
      
          <h3 align="center">Analisis Articulos Pedidos</h3>
          <div className="row">
          <div className="col-md-2">
            <Label>Codigo QR</Label>
          </div>
          <div className="col-md-2">
            <Label>Codigo</Label>
          </div>
          <div className="col-md-2">
            <Label>Articulo</Label>
          </div>
          </div>
          <div className="row">
          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="text"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                          </Input>
                          </div>
                        <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.codigo} </option>;
                              })}
                          </Input>
                          </div>

                          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.nombre} </option>;
                              })}
                          </Input>
                          </div>
                         
                        {/* <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div> */}
                        
                    </div>
                    <br />





                    {/* Tabla Movimientos */}
                     <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />

          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                      
            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.pedidos[0].idPedido}</td>
                      <td>{a.is_active}</td>
                      <td>{a.autorizado}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                    </tr>
                  )
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}


      {loader}
    </>
  );
}

export default AnalisisArticulosPedidos;
