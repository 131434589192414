import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../contexts/AuthContext";
import axios from "axios";
import { Badge, Table, Row, Col, ButtonGroup, Button } from "reactstrap";
import TablaFacturasInfo from "../../Facturas/TablaFacturasInfo";
import Header from "../../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";

function EdoCtaClientes() {
  const { user } = useContext(AuthContext);
  const URL_FACTURAS_CLIENTE = `${process.env.REACT_APP_URL_FACTURAS}/cliente/${user.clientes}`;
  const URL_ABONOS_FACTURAS_CLIENTE = `${process.env.REACT_APP_URL_ABONOS_FACTURAS}/cliente/${user.clientes}`;
  const [cargos, setCargos] = useState([]);
  const [abonos, setAbonos] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalTabla, setModalTabla] = useState(false);
  const toggleTabla = () => setModalTabla(!modalTabla);

  useEffect(() => {
    axios
      .get(URL_FACTURAS_CLIENTE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        setCargos(allCargos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ABONOS_FACTURAS_CLIENTE, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data;
        setAbonos(allAbonos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  let importesCargos = cargos.map((c) => c.importe);
  let totalCargos = importesCargos.reduce(
    (total, importe, index) => total + importe,
    0
  );

  let importesAbonos = abonos.map((a) => a.importe);
  let totalAbonos = importesAbonos.reduce(
    (total, importe, index) => total + importe,
    0
  );

  let saldo = totalCargos - totalAbonos;

  const arrayCargos = 
  cargos.map((c)=>{
  return(
    {fecha: c.fecha, movimiento: "Nota", importe:c.importe, forma_pago:"NA"})
})

const arrayAbonos = 
abonos.map((n)=>{
return(
  {fecha: n.fecha, movimiento: "Abono", importe:-n.importe, idAbonoFactura:n.idAbonoFactura, forma_pago:n.forma_pago})
})

  const arrayMovimientos = arrayCargos.concat(arrayAbonos).sort((a, b) =>a.fecha > b.fecha ? 1 : -1)

  var saldoParcial = 0
  var saldoParcialTabla = 0

  function PDFTabla() {
    const data = arrayMovimientos.map((a) => {
      saldoParcial = saldoParcial + a.importe
      const importe = "$" + new Intl.NumberFormat("en-US").format(a.importe);
      var saldoParcialFormato = "$" + new Intl.NumberFormat("en-US").format(saldoParcial);
      return [a.fecha, a.movimiento, a.forma_pago, importe, saldoParcialFormato];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 25, 25);
    doc.text(
      `Estado de Cuenta`,
      22,
      35
    );
    doc.text(
      `${user.nombre} ${user.apellido}`,
      22,
      42
    );
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Forma Pago",
          "Importe",
          "Saldo"
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "Saldo: ",
          "$" + new Intl.NumberFormat("en-US").format(saldo),
        ],
      ],
      showFoot: "lastPage",
    });
    // doc.save(`EdoCta.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = arrayMovimientos.map((a) => {
      return {
        Fecha: a.fecha,
        Movimiento: a.movimiento,
        Importe: a.importe,
      };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCta`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `EdoCta`,
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Importe",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Importe",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function PDFRecibo(idAbonoFactura, fecha, nombre, apellido, importe) {
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img, "png", 150, 10, 35, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img2, "png", 140, 20, 25, 25);
                                  // Horizontal, Vertical
    doc.setFontSize(16)
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`,65,65);
    doc.text(`__________________`,80,100);
    doc.text(`Firma`,100,110);
    doc.autoTable({
      head: [["No Recibo","Fecha", "Cliente", "Importe"]],
      body: [[idAbonoFactura, fecha, [nombre + " " + apellido], "$" + new Intl.NumberFormat("en-US").format(importe*-1)]],
      startY: 70,
    });
    // doc.save(`Recibo-${idAbonoFactura}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="card container">
        <Row>
          <Col sm={5}>
            <ButtonGroup>
              <Button
                size="sm"
                className="btn"
                color="danger"
                onClick={PDFTabla}
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              {/* <Button size="sm" className="btn" color="info">
                eMail <i class="fas fa-at"></i>
              </Button> */}
              <Button size="sm" className="btn" color="primary" onClick={excel}>
                Excel <i class="far fa-file-excel"></i>
              </Button>
            </ButtonGroup>
          </Col>
          <Col sm={7}>
            <h4 align="right">
              Saldo{" "}
              <Badge id="Total" color="danger" className="BadgeSize badge-pill">
                {"$" + new Intl.NumberFormat("en-US").format(saldo)}
              </Badge>
            </h4>
          </Col>
        </Row>
        {/* <Col md={12} align="right">
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="N4VGGRVNHR32Q"
            />
            <input
              type="image"
              src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/es_MX/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </Col> */}
        <Table size="sm" striped borderless className="table-responsive-xl">
          <thead>
            <tr >
              <th className="tituloTabla" align="left">
                Fecha
              </th>
              <th className="tituloTabla">Movimiento</th>
              <th className="tituloTabla">Forma de Pago</th>
              <th className="tituloTabla">Importe</th>
              <th className="tituloTabla">Saldo</th>
              <th className="tituloTabla">Recibo</th>
            </tr>
          </thead>
          <tbody>
            {arrayMovimientos
              .map((c) => {
                saldoParcialTabla = saldoParcialTabla + c.importe
                return (
                  <tr>
                      <td >{c.fecha}</td>
                    <td col="col-sm-2">{c.movimiento}</td>
                    <td col="col-sm-2">{c.forma_pago}</td>
                    <td >{"$" + new Intl.NumberFormat("en-US").format(c.importe)}</td>
                    <td >{"$" + new Intl.NumberFormat("en-US").format(saldoParcialTabla)}</td>
                    <td>
                    {c.movimiento=="Abono" ? (
                        <Button size="sm" className="btn" color="danger" 
                        onClick={(e) => PDFRecibo(c.idAbonoFactura, c.fecha, user.nombre, user.apellido, c.importe)}
                        >
                        <i class="far fa-file-pdf"></i>
                      </Button>):undefined    
                        }
                        </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default EdoCtaClientes;
