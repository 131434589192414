import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';
import n2wordsES from 'n2words/lib/i18n/ES.mjs'

function ReporteVentasArituclo() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS_ACUMULADOS = process.env.REACT_APP_URL_SURTIDOS_ACUMULADOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [tiposTelas, setTiposTelas] = useState([]);
  const [selectedTipoTela, setSelectedTipoTela] = useState("");
  const [selectedUnidad, setSelectedUnidad] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);const [selectedImpuestos, setSelectedImpuestos] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [totalTotalMetros, setTotalTotalMetros] = useState(0);
  const [totalTotalKilos, setTotalTotalKilos] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
    .get(`${URL_SURTIDOS_ACUMULADOS}ReporteVentasArticulo/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              idArticulo: a.idArticulo,
              articulo: a.nombre + " " + a.codigo,
              codigo: a.codigo,
              nombre: a.nombre,
              unidad: a.unidad,
              tipoTela: a.tipoTela,
              cantidad: a.cantidad,
              idEmpresa: a.idEmpresa,
              empresa: a.empresa,
              impuestos: a.impuestos.toString(),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);

    })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_TIPOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTiposTelas = response.data;
        setTiposTelas(allTiposTelas);
      })
      .catch((err) => {
        console.log(err);
      });
    }, [ selectedFechaInicio, selectedFechaFin]);

  let totalImportePDF = 0;
  let subTotalPDF = 0;
  let totalIVAPDF = 0;

  function PDFTabla() {
    let totalImportePDF = 0;
  let subTotalPDF = 0;
  let totalIVAPDF = 0;

  let computedComments = comments;

  if (search) {
    computedComments = computedComments.filter(
      (comment) =>
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.tipoTela.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search)
    );
  }
   if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresa.includes(selectedEmpresa)
      );
    }
    if (selectedTipoTela) {
      computedComments = computedComments.filter((e) =>
        e.tipoTela.includes(selectedTipoTela)
      );
    }

    if (selectedUnidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(selectedUnidad)
      );
    }
  
    if (selectedImpuestos) {
      computedComments = computedComments.filter((e) =>
        e.impuestos.includes(selectedImpuestos)
      );
    }

    let agrupado = computedComments.reduce(function (groups, item) {
      const val = item["idArticulo"]
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].articulo = item.articulo;
      groups[val].unidad = item.unidad;
      groups[val].tipoTela = item.tipoTela;
      groups[val].empresa = item.empresa;
      return groups;
    }, []);

    let agrupadoValues = Object.values(agrupado);
    computedComments = agrupadoValues
  // setTotalItems(computedComments.length);

  //Sorting comments
  if (
    sorting.field &&
    sorting.field != "cantidad" 
    // &&
    // sorting.field != "numero" 
  ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "cantidad" 
    // ||
    //   sorting.field == "numero" 
      )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "cantidad" 
    // ||
    //   sorting.field == "numero" 
      )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  const data = computedComments
    .map((a) => {
      // totalImportePDF = totalImportePDF + a.total_general;
      // subTotalPDF = subTotalPDF + a.subTotal;
      // totalIVAPDF = totalIVAPDF + a.iva;
         return [
          a.articulo,
          a.tipoTela,
          a.unidad,
          new Intl.NumberFormat("en-US", {maximumFractionDigits: 2 }).format(a.cantidad),
        ];
      }
    )
   
    
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Reporte Ventas Articulo`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Articulo", "Tipo Tela", "Unidad", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 50,
      // foot: [
      //   [
      //     "",
      //     "",
      //     "",
      //     "",
      //     "",
      //     "Totales",
      //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(subTotalPDF),
      //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalIVAPDF),
      //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalImportePDF),

      //   ],
      // ],
      showFoot: "lastPage",
    });
    // doc.save(`Notas.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {

    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.tipoTela.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search)
      );
    }
    if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresa.includes(selectedEmpresa)
      );
    }
    if (selectedTipoTela) {
      computedComments = computedComments.filter((e) =>
        e.tipoTela.includes(selectedTipoTela)
      );
    }
  
    if (selectedUnidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(selectedUnidad)
      );
    }

    if (selectedImpuestos) {
      computedComments = computedComments.filter((e) =>
        e.impuestos.includes(selectedImpuestos)
      );
    }

    let agrupado = computedComments.reduce(function (groups, item) {
      const val = item["idArticulo"]
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].articulo = item.articulo;
      groups[val].unidad = item.unidad;
      groups[val].tipoTela = item.tipoTela;
      groups[val].empresa = item.empresa;
      return groups;
    }, []);

    let agrupadoValues = Object.values(agrupado);
    computedComments = agrupadoValues
    // setTotalItems(computedComments.length);

   //Sorting comments
   if (
    sorting.field &&
    sorting.field != "cantidad" 
    // &&
    // sorting.field != "numero" 
  ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "cantidad" 
    // ||
    //   sorting.field == "numero" 
      )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "cantidad" 
    // ||
    //   sorting.field == "numero" 
      )
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  

    const dataExcel = computedComments.map((a) => {
        return {
          Articulo: a.articulo,
          TipoTela: a.tipoTela,
          Unidad: a.unidad,
          Cantidad: a.cantidad,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ReporteVentasArticulo";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ReporteVentasArticulo",
        sheetFilter: ["Articulo", "TipoTela", "Unidad", "Cantidad"],
        sheetHeader: ["Articulo", "TipoTela", "Unidad", "Cantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.tipoTela.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search)
      );
    }
     if (selectedEmpresa) {
        computedComments = computedComments.filter((e) =>
          e.idEmpresa.includes(selectedEmpresa)
        );
      }
      if (selectedTipoTela) {
        computedComments = computedComments.filter((e) =>
          e.tipoTela.includes(selectedTipoTela)
        );
      }

    if (selectedUnidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(selectedUnidad)
      );
    }
    
      if (selectedImpuestos) {
        computedComments = computedComments.filter((e) =>
          e.impuestos.includes(selectedImpuestos)
        );
      }
  
      let agrupado = computedComments.reduce(function (groups, item) {
        const val = item["idArticulo"]
        groups[val] = groups[val] || {
          idArticulo: item.idArticulo,
          cantidad: 0,
        };
        groups[val].cantidad += item.cantidad;
        groups[val].articulo = item.articulo;
        groups[val].unidad = item.unidad;
        groups[val].tipoTela = item.tipoTela;
        groups[val].empresa = item.empresa;
        return groups;
      }, []);
  
      let agrupadoValues = Object.values(agrupado);
      computedComments = agrupadoValues
    // setTotalItems(computedComments.length);
  
    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
      // &&
      // sorting.field != "numero" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
  
    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" 
      // ||
      //   sorting.field == "numero" 
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }
  
    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" 
      // ||
      //   sorting.field == "numero" 
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }
  
    const data = computedComments
      .map((a) => {
        // totalImportePDF = totalImportePDF + a.total_general;
        // subTotalPDF = subTotalPDF + a.subTotal;
        // totalIVAPDF = totalIVAPDF + a.iva;
           return [
            a.articulo,
            a.tipoTela,
            a.unidad,
            new Intl.NumberFormat("en-US", {maximumFractionDigits: 2 }).format(a.cantidad),
          ];
        }
      )
     
      
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF("landscape");
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 230, 30, 45, 15);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 140, 15, 25, 25);
      doc.text(`Reporte Ventas Articulo`, 20, 30);
      doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
      doc.autoTable({
        head: [["Articulo", "Tipo Tela", "Unidad", "Cantidad"]],
        body: dataPDFLimpia,
        startY: 50,
        // foot: [
        //   [
        //     "",
        //     "",
        //     "",
        //     "",
        //     "",
        //     "Totales",
        //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(subTotalPDF),
        //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalIVAPDF),
        //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalImportePDF),
  
        //   ],
        // ],
        showFoot: "lastPage",
      });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Reporte Ventas Articulo",
          email: mailTo,
          fileName: "ReporteVentasArticulo.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Reporte Ventas Articulo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  console.log("hola", comments);

  const headers = [
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Tipo Tela", field: "tipoTela", sortable: true },
    { name: "Unidad", field: "unidad", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
        comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
        comment.tipoTela.toLowerCase().includes(search.toLowerCase()) ||
        comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search)
      );
    }
    if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresa.includes(selectedEmpresa)
      );
    }
    if (selectedTipoTela) {
      computedComments = computedComments.filter((e) =>
        e.tipoTela.includes(selectedTipoTela)
      );
    }
  
    if (selectedUnidad) {
      computedComments = computedComments.filter((e) =>
        e.unidad.includes(selectedUnidad)
      );
    }

    if (selectedImpuestos) {
      computedComments = computedComments.filter((e) =>
        e.impuestos.includes(selectedImpuestos)
      );
    }

    let agrupado = computedComments.reduce(function (groups, item) {
      const val = item["idArticulo"]
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].articulo = item.articulo;
      groups[val].unidad = item.unidad;
      groups[val].tipoTela = item.tipoTela;
      groups[val].empresa = item.empresa;
      return groups;
    }, []);

    let agrupadoValues = Object.values(agrupado);
    computedComments = agrupadoValues
    setTotalItems(agrupadoValues.length);

    let met = computedComments.filter((e) => e.unidad == "Metros");
    let tot = met.map((c) => parseFloat(c.cantidad));
      let Ttot = tot.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalMetros(Ttot);

      let kil = computedComments.filter((e) => e.unidad == "Kilos");
    let totK = kil.map((c) => parseFloat(c.cantidad));
      let TtotK = totK.reduce((t, cantidad, index) => t + cantidad, 0);
      setTotalTotalKilos(TtotK);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" 
      // &&
      // sorting.field != "numero" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
  
    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" 
      // ||
      //   sorting.field == "numero" 
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }
  
    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" 
      // ||
      //   sorting.field == "numero" 
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedEmpresa,
    selectedTipoTela,
    selectedUnidad,
    selectedImpuestos,
  ]);

  let totalImporteTabla = 0;
  let subTotalTabla = 0;
  let totalIVATabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventasAdmin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Reporte Ventas Articulo</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte Ventas Articulo</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Impuestos</Label>
            <Input
                bsSize="sm"
                type="select"
                value={selectedImpuestos}
                onChange={(e) => {
                  setSelectedImpuestos(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value={16}>Si</option>
                <option value={0}>No</option>
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Empresa</Label>
            <Input
                bsSize="sm"
                type="select"
                value={selectedEmpresa}
                onChange={(e) => {
                  setSelectedEmpresa(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {empresas
                  .sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Tipo Tela</Label>
            <Input
                bsSize="sm"
                type="select"
                value={selectedTipoTela}
                onChange={(e) => {
                  setSelectedTipoTela(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {tiposTelas
                  .sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Unidad</Label>
            <Input
                bsSize="sm"
                type="select"
                value={selectedUnidad}
                onChange={(e) => {
                  setSelectedUnidad(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Metros">Metros</option>
                <option value="Kilos">Kilos</option>
              </Input>
            </Col>
            
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            {/* <tr>
              <td></td>
              <td></td>
              <td></td>
            <td style={{ paddingTop: "0px" }}>
            <Input
                bsSize="sm"
                type="select"
                value={selectedEmpresa}
                onChange={(e) => {
                  setSelectedEmpresa(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {empresas
                  .sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
               <td style={{ paddingTop: "0px" }}>
            <Input
                bsSize="sm"
                type="select"
                value={selectedVendedor}
                onChange={(e) => {
                  setSelectedVendedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores.map((a) => {
                            if (a.vendedor == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
              </Input>
              </td>
            </tr> */}
            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.articulo}</td>
                      <td>{a.tipoTela}</td>
                      <td>{a.unidad}</td>
                      <td>{new Intl.NumberFormat("en-US", {maximumFractionDigits: 2 }).format(a.cantidad)}</td>
                    </tr>
                  );

              })}


              <tr>
                <td></td>
                <td></td>
                <td className="negrita" align="center">TOTAL METROS</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalTotalMetros)}</td>
              </tr> 

              <tr>
              <td></td>
              <td></td>
                <td className="negrita" align="center">TOTAL KILOS</td>
                <td className="negrita">{new Intl.NumberFormat("en-US", {maximumFractionDigits: 2}).format(totalTotalKilos)}</td>
              </tr> 

            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ReporteVentasArituclo;
