import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import moment from "moment";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

import { v4 as uuidv4 } from "uuid";

function ListadoApartados() {
  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_APARTADOS = process.env.REACT_APP_URL_APARTADOS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ARTICULOS_APARTADOS = process.env.REACT_APP_URL_ARTICULOS_APARTADOS;
  const URL_APARTADOS_ACUMULADOS =
    process.env.REACT_APP_URL_APARTADOS_ACUMULADOS;

  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_SURTIDOS_ACUMULADOS = process.env.REACT_APP_URL_SURTIDOS_ACUMULADOS;

  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;

  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [apartados, setApartados] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [pedimento, setPedimento] = useState("");
  const [factura_cliente, setFacturaCliente] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [partida, setPartida] = useState("");
  const [aduana, setAduana] = useState("");
  const [fecha_aduana, setFechaAduana] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_apartado, setNumeroApartado] = useState("");

  const [limiteCredito, setLimiteCredito] = useState(0);
  const [saldoCliente, setSaldoCliente] = useState(0);
  const [totalApartado, setTotalApartado] = useState(0);

  const [idEdit, setIdEdit] = useState("");

  const [articulosApartado, setArticulosApartado] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);
  const [totalDinero, setTotalDinero] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalImpuestos, setModalImpuestos] = useState(false);
  const toggleImpuestos = () => setModalImpuestos(!modalImpuestos);
  const [fechaSurtido, setFechaSurtido] = useState(endDate);
  const [impuestos, setImpuestos] = useState("");
  const [observacionesNota, setObservacionesNota] = useState("NA");

  const [validaBoton, setValidaBoton] = useState(true);

  const [articulosApartadoSurtido, setArticulosApartadoSurtido] = useState([]);
  const [articulosPedidosSurtido, setArticulosPedidosSurtido] = useState([]);

  const [articulosPorSurtir, setArticulosPorSurtir] = useState([]);
  const [idsInventario, setIdsInventario] = useState([]);
  const [articulosPedido, setArticulosPedido] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState("");
  const [pedidos, setPedidos] = useState([]);
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [fecha_pedido, setFechaPedido] = useState("");
  const [rollosTotales, setRollosTotales] = useState(0);
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);
  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [idColaboradores, setIdColaboradores] = useState("");
  const [idApartadoSurtir, setIdApartadoSurtir] = useState("");
  const [idPedidoSurtir, setIdPedidoSurtir] = useState("");
  const [idClienteSurtir, setIdClienteSurtir] = useState("");
  const [fechaVencimiento, setFechaVencimiento] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventario: "",
      articulos: "",
      idArticulo: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      tono: "",
      idArticuloPedido: "",
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("Si");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_APARTADOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allApartados = response.data;
        // Array para pagination
        let arrayTabla = allApartados
          .sort((a, b) => (a.idApartado < b.idApartado ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.pedidos[0].clientes[0].nombre_comercial,
              idClientes: a.pedidos[0].clientes[0]._id,
              limiteCredito: a.pedidos[0].clientes[0].limiteCredito,
              saldoCliente: a.pedidos[0].clientes[0].saldo,
              observaciones: a.pedidos[0].observaciones,
              numero: a.idApartado,
              surtido: a.surtido,
              is_active: a.is_active,
              plazo: a.pedidos[0].plazo,
              total_cantidad: a.total_cantidad,
              total_general: a.total_general,
              idColaboradores: a.pedidos[0].colaboradores[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setApartados(allApartados);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PEDIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPedidos = res.data;
        setPedidos(allPedidos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 25, 25);
    doc.text(`Listado de Apartados`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Apartao"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    // doc.save(`Apartados.pdf`);
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Apartao: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoApartados";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoApartados",
        sheetFilter: ["Fecha", "Cliente", "Pedido", "Apartao"],
        sheetHeader: ["Fecha", "Cliente", "Pedido", "Apartao"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.fecha, a.clientes, a.pedidos, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 25, 25);
    doc.text(`Listado de Apartados`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Cliente", "Pedido", "Apartao"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Apartados",
          email: mailTo,
          fileName: "ListadoApartados.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Apartados.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos
  ) {
    await axios
      .get(`${URL_ARTICULOS_APARTADOS}/apartado/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosApartado = response.data;
        let arrayTabla = allArticulosApartado
          .map((a) => {
            if (a.inventarios[0].vendido == "No") {
              return {
                id: a.inventarios[0]._id,
                articulos:
                  a.inventarios[0].articulos[0].codigo +
                  " " +
                  a.inventarios[0].articulos[0].nombre,
                unidad: a.inventarios[0].articulos[0].unidad,
                colores: a.inventarios[0].colores[0].name,
                cantidad: a.inventarios[0].cantidad,
                cantidadString: a.inventarios[0].cantidad.toString(),
                idArticulo: a.inventarios[0].articulos[0]._id,
                idColor: a.inventarios[0].colores[0]._id,
                tono: a.inventarios[0].tono,
                precio: a.inventarios[0].articulosPedido[0].precio,
                subtotal: parseFloat(a.inventarios[0].articulosPedido[0].precio) * parseFloat(a.inventarios[0].cantidad)
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let cantidadRollos = dataFinal.length;

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["idColor"] + item["tono"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            idColores: item.idColores,
            tono: item.tono,
            cantidad: 0,
            rollos: 0,
            cantidadString: "",
            subtotal: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].subtotal += item.subtotal;
          groups[val].cantidadString += item.cantidadString + " / ";
          groups[val].articulos = item.articulos;
          groups[val].unidad = item.unidad;
          groups[val].colores = item.colores;
          groups[val].precio = item.precio;
          groups[val].descuento = item.descuento;
          groups[val].tono = item.tono;
          groups[val].rollos += 1;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        setTotalCantidadEdit(TM + TK)

        let total_importe = 0;
        let totalParcial = 0;
        let totalDesc = 0;
        const data = agrupadoValues.map((a) => {
          totalParcial = a.cantidad * a.precio;
          totalDesc = totalParcial - (totalParcial * a.descuento) / 100;
          total_importe = total_importe + a.subtotal;
          return [
            a.articulos,
            a.colores,
            a.tono,
            a.rollos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.subtotal),
            a.cantidadString,
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 140, 15, 25, 25);
        doc.text(`Apartado # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Cliente ${clientes}`, 20, 30);
        doc.text(`Pedido # ${pedidos}`, 20, 35);
        doc.text(
          `Metros ${new Intl.NumberFormat("en-US").format(
            TM
          )} / Kilos ${new Intl.NumberFormat("en-US").format(
            TK
          )} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`,
          20,
          40
        );
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Color",
              "Tono",
              "Rollos",
              "Cantidad",
              "Precio",
              "Subtotal",
              "Desgloce Rollos",
            ],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_importe),
            ],
          ],
          showFoot: "lastPage",
        });

        // doc.save(`Apartado-Resumen-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  async function ExcelOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos
  ) {
    await axios
      .get(`${URL_ARTICULOS_APARTADOS}/apartado/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosApartado = response.data;
        let arrayTabla = allArticulosApartado
          .map((a) => {
            if (a.inventarios[0].vendido == "No") {
              return {
                id: a.inventarios[0]._id,
                articulos:
                  a.inventarios[0].articulos[0].codigo +
                  " " +
                  a.inventarios[0].articulos[0].nombre,
                unidad: a.inventarios[0].articulos[0].unidad,
                colores: a.inventarios[0].colores[0].name,
                cantidad: a.inventarios[0].cantidad,
                cantidadString: a.inventarios[0].cantidad.toString(),
                idArticulo: a.inventarios[0].articulos[0]._id,
                idColor: a.inventarios[0].colores[0]._id,
                tono: a.inventarios[0].tono,
                precio: a.inventarios[0].articulosPedido[0].precio,
                subtotal: parseFloat(a.inventarios[0].articulosPedido[0].precio) * parseFloat(a.inventarios[0].cantidad)
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let cantidadRollos = dataFinal.length;

        // let agrupado = dataFinal.reduce(function (groups, item) {
        //   const val = item["idArticulo"] + item["idColor"] + item["tono"];
        //   groups[val] = groups[val] || {
        //     idArticulo: item.idArticulo,
        //     idColores: item.idColores,
        //     tono: item.tono,
        //     cantidad: 0,
        //     rollos: 0,
        //     cantidadString: "",
        //     subtotal: 0,
        //   };
        //   groups[val].cantidad += item.cantidad;
        //   groups[val].subtotal += item.subtotal;
        //   groups[val].cantidadString += item.cantidadString + " / ";
        //   groups[val].articulos = item.articulos;
        //   groups[val].unidad = item.unidad;
        //   groups[val].colores = item.colores;
        //   groups[val].precio = item.precio;
        //   groups[val].descuento = item.descuento;
        //   groups[val].tono = item.tono;
        //   groups[val].rollos += 1;
        //   return groups;
        // }, []);

        // let agrupadoValues = Object.values(agrupado);

        const dataExcel = dataFinal.map((a) => {

          return {
            Articulo: a.articulos,
            Color: a.colores,
            Tono: a.tono,
            // Rollos: a.rollos,
            Cantidad: a.cantidad,
            // CantidadRollos: a.cantidadString,
            Precio: a.precio,
            Total: a.subtotal,
}
          ;
        });
        const dataExportExcel = Object.values(dataExcel);
                const dataExcelLimpia = dataExportExcel.filter(function (el) {
                  return el != null;
                });

                var option = {};

                option.fileName = `Apartado-${numero}`;

                option.datas = [
                  {
                    sheetData: dataExcelLimpia,
                    sheetName: "Apartado",
                    sheetFilter: [
                      "Articulo",
                      "Color",
                      "Tono",
                      // "Rollos",
                      "Cantidad",
                      // "CantidadRollos",
                      "Precio",
                      "Total",
                    ],
                    sheetHeader: [
                      "Articulo",
                      "Color",
                      "Tono",
                      // "Rollos",
                      "Cantidad",
                      // "CantidadRollos",
                      "Precio",
                      "Total",
                    ],
                  },
                ];

                var toExcel = new ExportJsonExcel(option);
                toExcel.saveExcel();
              })
      .catch((err) => {
        console.log(err);
      });
  }

  function editApartados(event) {
    event.preventDefault();
    const URL_APARTADO_EDIT = `${process.env.REACT_APP_URL_APARTADOS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_APARTADO_EDIT,
          {
            fecha,
            pedimento,
            partida,
            observaciones,
            aduana,
            fecha_aduana,
            pais_origen,
            total_cantidad: total_cantidad_edit
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Apartado",
            detalle: `${numero_apartado} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: false },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    activo,
  ]);

  function EditArticulos(idApartado, numero_apartado, idPedido) {
    setIdEdit(idApartado);
    setNumeroApartado(numero_apartado);
    setSelectedPedido(idPedido);

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtido/${idPedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulosPorSurtir(allArticulos);
        let result = allArticulos.map(({ _id }) => _id);
        setIdsInventario(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        setArticulosPedido(allArticulosPedido);
      })
      .catch((err) => {
        console.log(err);
      });

    pedidos.map((a) => {
      if (a._id == idPedido) {
        setFechaPedido(a.fecha);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.clientes[0].razon_social);
        setIdCliente(a.clientes[0]._id);
      }
    });

    axios
      .get(`${URL_ARTICULOS_APARTADOS}/apartado/${idApartado}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        let arrayTabla = allArticulos
          .map((a) => {
            if (a.inventarios[0].vendido == "No") {
              return {
                idArtApartado: a._id,
                idInventario: a.inventarios[0]._id,
                idArticulo: a.inventarios[0].articulos[0]._id,
                articulo:
                  a.inventarios[0].articulos[0].codigo +
                  " / " +
                  a.inventarios[0].articulos[0].nombre +
                  " / " +
                  a.inventarios[0].colores[0].name,
                unidad: a.inventarios[0].articulos[0].unidad,
                cantidad: a.inventarios[0].cantidad,
                tono: a.inventarios[0].tono,
                colores: a.inventarios[0].colores[0]._id,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosApartado(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        setTotalCantidadEdit(TM + TK)

      })
      .catch((err) => {
        console.log(err);
      });

    toggleEditArticulos();
  }

  function ActualizaTotales() {
    let mtsExistentes = articulosApartado.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosApartado.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    setTotalCantidadEdit(TM + TK)

    let juntos = articulosApartado.concat(inputFields);

    let tr = 0;
    juntos.map((a) => {
      if (a.articulos != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mtsExistentes = articulosApartado.filter((e) => e.unidad == "Metros");
    let mtsNuevos = values.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosApartado.filter((e) => e.unidad == "Kilos");
    let KGNuevos = values.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    setTotalCantidadEdit(TM + TK)

  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventario: "",
        articulos: "",
        idArticulo: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        tono: "",
        idArticuloPedido: "",
        precio: 0,
        total: 0,
      },
    ]);
  };

  function impuestosSurtir(idApartado, idPedido, idCliente, plazo, total_cantidad, idColaboradores, limiteCredito, saldoCliente, total_general){

    let fechaVenc = new Date();
    fechaVenc.setDate(fechaVenc.getDate() + plazo);
    let fechaVenc2 = moment(fechaVenc).format("YYYY-MM-DD");

    if((saldoCliente + total_general) > limiteCredito){
      Swal.fire({
        title: "El cliente excede el límite de crédito",
        // text: `
        // Limite: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(limiteCredito)} 
        // Saldo: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format((saldoCliente).toFixed(2))} 
        // Total Nota: ${new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(total_general)}
        // `,
        icon: "error",
        confirmButtonText: "Cerrar",
      })
    }else{
      setFechaVencimiento(fechaVenc2);
      setIdApartadoSurtir(idApartado);
      setIdPedidoSurtir(idPedido);
      setIdClienteSurtir(idCliente);
      setTotalCantidad(total_cantidad)
      setIdColaboradores(idColaboradores)
      setLimiteCredito(limiteCredito)
      setSaldoCliente(saldoCliente)
      setTotalApartado(totalApartado)
      toggleImpuestos();
    }
  
  }

  async function surtir() {
    await axios
      .get(`${URL_ARTICULOS_APARTADOS}/apartado/${idApartadoSurtir}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosApartado = response.data;
        // Array para pagination
        let arrayTabla = allArticulosApartado
          .map((a) => {
            if (a.inventarios[0].vendido == "No") {
              return {
                cantidad: a.inventarios[0].cantidad,
                idArticulo: a.inventarios[0].articulos[0]._id,
                colores: a.inventarios[0].colores[0]._id,
                tono: a.inventarios[0].tono,
                articulosPedido: a.inventarios[0].articulosPedido[0]._id,
                precio: a.inventarios[0].articulosPedido[0].precio,
                total:
                  parseFloat(a.inventarios[0].cantidad) *
                  parseFloat(a.inventarios[0].articulosPedido[0].precio),
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });
        setArticulosApartadoSurtido(allArticulosApartado);

        let totalDin = arrayTabla.map((c) => parseFloat(c.total));
        let subTotal = totalDin.reduce((t, total, index) => t + total, 0);

        let iva = (subTotal * impuestos) / 100;

        let totalGeneral = subTotal + iva;

        let agrupado = arrayTabla.reduce(function (groups, item) {
          const val =
            item["idArticulo"] +
            item["colores"] +
            item["tono"] +
            item["articulosPedido"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            colores: item.colores,
            tono: item.tono,
            articulosPedido: item.articulosPedido,
            cantidad: 0,
            total: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].total += item.total;
          groups[val].idArticulo = item.idArticulo;
          groups[val].colores = item.colores;
          groups[val].tono = item.tono;
          groups[val].articulosPedido = item.articulosPedido;
          return groups;
        }, []);

        var CA = Object.values(agrupado);

        axios
          .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPedidoSurtir}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            var allArticulosPedidos = response.data;

            allArticulosPedidos.map((AP) => {
              CA.map((c) => {
                if (
                  AP.articulos[0]._id == c.idArticulo &&
                  AP.colores[0]._id == c.colores &&
                  AP.tono == c.tono
                ) {
                  AP.surtido = parseFloat(AP.surtido) + parseFloat(c.cantidad);
                  AP.pendiente_surtir =
                    parseFloat(AP.pendiente_surtir) - parseFloat(c.cantidad);
                }
                return AP;
              });
            });

            setArticulosPedidosSurtido(allArticulosPedidos);

            Swal.fire({
              title: "Estas seguro?",
              text: "Se va a Surtir!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, Surtir!",
            }).then(async (result) => {
              if (result.isConfirmed) {
                saveSurtido(
                  idApartadoSurtir,
                  idPedidoSurtir,
                  idClienteSurtir,
                  allArticulosPedidos,
                  CA,
                  subTotal,
                  iva,
                  totalGeneral
                );
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function saveSurtido(
    idApartado,
    idPedido,
    idCliente,
    allArticulosPedidos,
    CA,
    subTotal,
    iva,
    totalGeneral
  ) {
    setValidaBoton(false);
    toggleProgreso();

    let totalRollos = allArticulosPedidos.length + CA.length + 1;

    let sal 
    if (impuestos == 16){
      sal = 0
    }else{
      sal = totalGeneral.toFixed(2)
    }

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha: fechaSurtido,
            pedidos: idPedido,
            user: user.id,
            observaciones: observacionesNota,
            subTotal: subTotal.toFixed(2),
            iva: iva.toFixed(2),
            total_general: totalGeneral.toFixed(2),
            saldo: sal,
            impuestos,
            clientes: idCliente,
            is_active: "Si",
            fechaVencimiento,
            empresas: selectedEmpresa,
            total_cantidad,
            colaboradores:idColaboradores,
            comisionPagada:"No"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          axios
            .patch(
              `${URL_INVENTARIOS}/apartadoSurtido/${idApartado}`,
              {
                surtidos: data.data._id,
                vendido: "Si",
                fecha: fechaSurtido,
                clientes: idCliente,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `Pedido ${idPedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });

          allArticulosPedidos.map((w) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${w._id}`,
                {
                  surtido: w.surtido,
                  pendiente_surtir: w.pendiente_surtir,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Surtido",
                        detalle: `Pedido ${idPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });

          // Pegamos a Surtidos Acumulado

          CA.map((w) => {
            axios
              .post(
                `${URL_SURTIDOS_ACUMULADOS}`,
                {
                  surtidos: data.data._id,
                  articulos: w.idArticulo,
                  articulosPedido: w.articulosPedido,
                  colores: w.colores,
                  cantidad: w.cantidad,
                  pedidos: idPedido,
                  fecha: fechaSurtido,
                  clientes: idCliente,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Surtido",
                        detalle: `Pedido ${idPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });

          // Terminamos de post a surtidos acumulado
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  }

  function BuscaArticulo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsInventario.includes(event.target.value)) {
        if (
          inputFields.filter((e) => e.idInventario == event.target.value)
            .length > 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El rollo ya fue escaneado!",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.idInventario = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        } else {
          articulosPorSurtir.map((a) => {
            if (a._id == event.target.value) {
              let articulosApartado = a._id;
              let articulo = a.articulo;
              let idArticulo = a.idArticulo;
              let unidadArticulo = a.unidadArticulo;
              let color = a.color;
              let cantidad = a.cantidad;
              let tono = a.tono;
              handleChangeInputArticulo(
                id,
                articulosApartado,
                articulo,
                idArticulo,
                unidadArticulo,
                color,
                cantidad,
                tono
              );
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo no pertenece al pedido!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventario = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventario = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticulo = (
    id,
    articulosApartado,
    articulo,
    idArticulo,
    unidadArticulo,
    color,
    cantidad,
    tono
  ) => {
    let idArticuloPedido;
    let precio;

    articulosPedido.map((a) => {
      if (a.articulos[0]._id == idArticulo && a.colores[0]._id == color) {
        idArticuloPedido = a._id;
        precio = a.precio;
      }
    });

    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventario = articulosApartado;
        i.articulos = articulo;
        i.idArticulo = idArticulo;
        i.unidad = unidadArticulo;
        i.colores = color;
        i.cantidad = cantidad;
        i.tono = tono;
        i.idArticuloPedido = idArticuloPedido;
        i.precio = precio;
        i.total = parseFloat(precio) * parseFloat(cantidad);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  async function borrarExistentes(idInventario) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se va a Borrar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Borrar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_INVENTARIOS}/desApartar/${idInventario}`,
            {
              apartado: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const values = [...articulosApartado];
            values.splice(
              values.findIndex((value) => value.idInventario === idInventario),
              1
            );
            setArticulosApartado(values);
            let mtsExistentes = values.filter((e) => e.unidad == "Metros");
            let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
            let mts = mtsExistentes.concat(mtsNuevos);
            let totalMts = mts.map((c) => parseFloat(c.cantidad));
            let TM = totalMts.reduce((t, total, index) => t + total, 0);
            setTotalMetrosEdit(TM);

            let KGExistentes = values.filter((e) => e.unidad == "Kilos");
            let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
            let KG = KGExistentes.concat(KGNuevos);
            let totalKG = KG.map((c) => parseFloat(c.cantidad));
            let TK = totalKG.reduce((t, total, index) => t + total, 0);
            setTotalKilosEdit(TK);

            setTotalCantidadEdit(TM + TK)

            Swal.fire("Good job!", "Creado con exito", "success");
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log(error);
          });
      }
    });
  }

  const saveArticulosApartado = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalRollos = inputFields.length;
    try {
      inputFields.map((a) => {
        if (a.articulos != "") {
          axios
            .patch(
              `${URL_INVENTARIOS}/apartado/${a.idInventario}`,
              {
                apartados: idEdit,
                apartado: "Si",
                articulosPedido: a.idArticuloPedido,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Apartado",
                      detalle: `Pedido ${selectedPedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            });
        } else {
          totalRollos = totalRollos - 1;
          if (totalRollos == 0) {
            axios
              .post(
                URL_LOGS,
                {
                  tipo: "Crear Apartado",
                  detalle: `Pedido ${selectedPedido}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function cancelarApartado(idApartado) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Apartado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_APARTADOS}Cancel/${idApartado}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_surtidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.surtidos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ApartadosCreate"
                >
                  Nuevo Apartado
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Apartado
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Apartados</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Apartados</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                return (
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                    <td>{a.observaciones}</td>
                    <td>{a.pedidos}</td>
                    <td>
                    <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={(e) =>
                          ExcelOC(
                            a.fecha,
                            a.idClientes,
                            a.clientes,
                            a.observaciones,
                            a.numero,
                            a._id,
                            a.user,
                            a.pedidos
                          )
                        }
                      >
                        <i class="far fa-file-excel"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={(e) =>
                          PDFOC(
                            a.fecha,
                            a.idClientes,
                            a.clientes,
                            a.observaciones,
                            a.numero,
                            a._id,
                            a.user,
                            a.pedidos
                          )
                        }
                      >
                        <i class="far fa-file-pdf"></i>
                      </Button>
                      
                      {a.is_active == "Si" && a.surtido == "No" ? (
                        <>
                          <Button
                            size="sm"
                            className="btn"
                            color="primary"
                            onClick={(e) => impuestosSurtir(a._id, a.idPedido, a.idClientes, a.plazo, a.total_cantidad, a.idColaboradores, a.limiteCredito, a.saldoCliente, a.total_general)}

                          >
                            <i class="far fa-paper-plane"></i>
                          </Button>

                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              EditArticulos(a._id, a.numero, a.idPedido)
                            }
                          >
                            <i class="fas fa-cart-plus"></i>
                          </Button>

                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) => cancelarApartado(a._id)}
                          >
                            <i class="fas fa-ban"></i>
                          </Button>
                        </>
                      ) : undefined}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Apartado # {numero_apartado}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pedimento</Label>
              <Input
                type="text"
                placeholder="Pedimento"
                value={pedimento}
                required
                onChange={(e) => {
                  setPedimento(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Factura Cliente</Label>
              <Input
                type="text"
                placeholder="Factura Cliente"
                value={factura_cliente}
                required
                onChange={(e) => {
                  setFacturaCliente(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Partida</Label>
              <Input
                type="text"
                placeholder="Partida"
                value={partida}
                required
                onChange={(e) => {
                  setPartida(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Aduana</Label>
              <Input
                type="text"
                placeholder="Aduana"
                value={aduana}
                required
                onChange={(e) => {
                  setAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Aduana</Label>
              <Input
                type="date"
                placeholder="Fecha Aduana"
                value={fecha_aduana}
                required
                onChange={(e) => {
                  setFechaAduana(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editApartados}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Apartado # {numero_apartado}</h4>
          <h4>
            TOTALES {total_kilos_edit} kg. / {total_metros_edit} mts.
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Codigo</Label>
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Unidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Tono</Label>
            </Col>
          </Row>

          {/* Articulos Existentes*/}

          {articulosApartado.map((ap) => (
            <div key={ap.idArtApartado}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="codigo"
                    type="string"
                    value={ap.idInventario}
                    disabled
                  ></Input>
                </Col>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulo"
                    type="string"
                    value={ap.articulo}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={ap.unidad}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="string"
                    value={ap.cantidad}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="tono"
                    type="string"
                    value={ap.tono}
                    disabled
                  ></Input>
                </Col>
                <Button
                  style={{ marginTop: "0px" }}
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={() => borrarExistentes(ap.idInventario)}
                  tabindex="-1"
                >
                  <i class="fas fa-minus"></i>
                </Button>
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="idInventario"
                    type="string"
                    value={inputField.idInventario}
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  ></Input>
                </Col>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="string"
                    value={inputField.articulos}
                    disabled
                  ></Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="unidad"
                    type="string"
                    value={inputField.unidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="string"
                    value={inputField.cantidad}
                    disabled
                  ></Input>
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="tono"
                    type="string"
                    value={inputField.tono}
                    disabled
                  ></Input>
                </Col>

                <Button
                  style={{ marginTop: "0px" }}
                  size="sm"
                  className="btn"
                  color="danger"
                  disabled={inputFields.length === 1}
                  onClick={() => handleRemoveFields(inputField.id)}
                  tabindex="-1"
                >
                  <i class="fas fa-minus"></i>
                </Button>
                <Button
                  style={{ marginTop: "0px" }}
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={handleAddFields}
                  tabindex="-1"
                >
                  <i class="fas fa-plus"></i>
                </Button>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(total_kilos_edit)} kg. /{" "}
                {new Intl.NumberFormat("en-US").format(total_metros_edit)} mts.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="success"
            onClick={saveArticulosApartado}
            tabindex="-1"
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalImpuestos} toggle={toggleImpuestos}>
        <ModalHeader toggle={toggleImpuestos}>
          <h4>Generar Nota</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Empresa</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEmpresa}
                required
                onChange={(e) => {
                  setSelectedEmpresa(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {empresas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={6}>
              <Label>Fecha Surtido</Label>
              <Input
                // bsSize="sm"
                type="date"
                placeholder="Fecha Surtido"
                value={fechaSurtido}
                required
                onChange={(e) => {
                  setFechaSurtido(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Impuestos
              </Label>
              <Input
                // bsSize="sm"
                type="select"
                value={impuestos}
                onChange={(e) => {
                  setImpuestos(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value={0}>No</option>
                <option value={16}>Si</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                // bsSize="sm"
                type="text"
                value={observacionesNota}
                onChange={(e) => {
                  setObservacionesNota(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Button
            size="sm"
            color="success"
            onClick={(e) => surtir()}
            tabindex="-1"
          >
            Guardar
          </Button>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoApartados;
