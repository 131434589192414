import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoDevolucionesAdmin() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_DEVOLUCIONES =
    process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const BANCO_DEVOLUCION = process.env.REACT_APP_BANCO_DEVOLUCION;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [idEdit, setIdEdit] = useState("");
  const [articulosDev, setArticulosDev] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedAplicada, setSelectedAplicada] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedImpuesto, setSelectedImpuesto] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);

  const [numero, setNumero] = useState("");
  const [clienteName, setClienteName] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalAplicaDevolucion, setModalAplicaDevolucion] = useState(false);
  const toggleAplicaDevolucion = () =>
    setModalAplicaDevolucion(!modalAplicaDevolucion);
  const [cliente, setCliente] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [total, setTotal] = useState(0);

  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(
        `${URL_DEVOLUCIONES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          // .sort((a, b) => (a.idDevolucion > b.idDevolucion ? 1 : -1))
          .map((a) => {
            if (a.surtidos[0].fecha > "2023-02-07") {
              if(a.impuestos > 0){
                return {
                  _id: a._id,
                  numero: a.idDevolucion,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.surtidos[0].pedidos[0].colaboradores[0].nombre +
                    " " +
                    a.surtidos[0].pedidos[0].colaboradores[0].apellido,
                  idColaboradores: a.surtidos[0].pedidos[0].colaboradores[0]._id,
                  observaciones: a.observaciones,
                  subTotal: a.subTotal,
                  iva: a.iva,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  impuestos: a.impuestos,
                  aplicada: "Si",
                  surtidos: a.surtidos[0].idSurtido,
                  idEmpresas: a.surtidos[0].empresas[0]._id,
                  empresas: a.surtidos[0].empresas[0].name,
                };
              }else{
                return {
                  _id: a._id,
                  numero: a.idDevolucion,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.surtidos[0].pedidos[0].colaboradores[0].nombre +
                    " " +
                    a.surtidos[0].pedidos[0].colaboradores[0].apellido,
                  idColaboradores: a.surtidos[0].pedidos[0].colaboradores[0]._id,
                  observaciones: a.observaciones,
                  subTotal: a.subTotal,
                  iva: a.iva,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  impuestos: a.impuestos,
                  aplicada: a.aplicada,
                  surtidos: a.surtidos[0].idSurtido,
                  idEmpresas: a.surtidos[0].empresas[0]._id,
                  empresas: a.surtidos[0].empresas[0].name,
                };
              }
              }
              
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaDevoluciones = comments.sort((a, b) =>
    a.idDevolucion > b.idDevolucion ? 1 : -1
  );

  function PDFTabla() {
    let TotalTabla = 0;
    let Cantidad = 0;
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idColaboradores) &&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)&&
        (selectedEmpresa == 0 || selectedEmpresa == a.idEmpresas)
      ) {
        TotalTabla = TotalTabla + a.total_general;
        Cantidad = Cantidad + a.total_cantidad;
        return [
          a.numero,
          a.fecha,
          a.empresas,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Empresa", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(Cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTabla),
        ],
      ],
    });
    // doc.save(`Devoluciones.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idColaboradores) &&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDevoluciones";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDevoluciones",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalTabla = 0;
    let Cantidad = 0;
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 ||
          selectedColaborador == a.idColaboradores) &&
        (selectedAplicada == 0 || selectedAplicada == a.aplicada)
      ) {
        TotalTabla = TotalTabla + a.total_general;
        Cantidad = Cantidad + a.total_cantidad;
        return [
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Vendedor", "Cliente", "Cantidad", "Total"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(Cantidad),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTabla),
        ],
      ],
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Devoluciones",
          email: mailTo,
          fileName: "ListadoDevoluciones.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Devoluciones.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function EditDevolucion(
    idDev,
    total_general,
    impuestos,
    cliente,
    numero,
    subTotal,
    iva
  ) {
    setIdEdit(idDev);
    setTotalGeneralEdit(total_general);
    setImpuestos(impuestos);
    setClienteName(cliente);
    setNumero(numero);
    setSubTotal(subTotal);
    setIva(iva);
    axios
      .get(`${URL_ARTICULOS_DEVOLUCIONES}/devolucion/${idDev}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              idArticulo: a.articulos[0]._id,
              articuloName: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
              idColor: a.colores[0]._id,
              colorName: a.colores[0].name,
              cantidad: a.inventarios[0].cantidad,
              precio: a.precio,
              total: a.precio * a.inventarios[0].cantidad,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        // let agrupado = dataFinal.reduce(function (groups, item) {
        //   const val = item["idArticulo"] + item["idColor"];
        //   groups[val] = groups[val] || {
        //     idArticulo: item.idArticulo,
        //     idColor: item.idColor,
        //     cantidad: 0,
        //     rollos: 0,
        //     total: 0,
        //   };
        //   groups[val].cantidad += item.cantidad;
        //   groups[val].total += item.total;
        //   groups[val].articuloName = item.articuloName;
        //   groups[val].idArticulo = item.idArticulo;
        //   groups[val].idColor = item.idColor;
        //   groups[val].colorName = item.colorName;
        //   groups[val].precio = item.precio;
        //   groups[val].rollos += 1;
        //   return groups;
        // }, []);

        // let agrupadoValues = Object.values(agrupado);
        // setArticulosDev(agrupadoValues);

        setArticulosDev(dataFinal);
        
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  async function PDFOC(
    fecha,
    clientes,
    numero,
    idPDFOC,
    impuestos,
    area,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    surtidos
  ) {
    await axios
      .get(`${URL_ARTICULOS_DEVOLUCIONES}/devolucion/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosDevolucion = response.data;
        let arrayTabla = allArticulosDevolucion
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos:
                a.inventarios[0].articulos[0].codigo +
                " " +
                a.inventarios[0].articulos[0].nombre,
              idColor: a.inventarios[0].colores[0]._id,
              colores: a.inventarios[0].colores[0].name,
              unidad: a.inventarios[0].articulos[0].unidad,
              cantidad: a.inventarios[0].cantidad,
              cantidadString: a.inventarios[0].cantidad.toString(),
              tono: a.inventarios[0].tono,
              precio: a.precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cantidadRollos = dataFinal.length;
        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["idColor"] + item["tono"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            idColores: item.idColores,
            cantidad: 0,
            rollos: 0,
            cantidadString: "",
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cantidadString += item.cantidadString + " / ";
          groups[val].articulos = item.articulos;
          groups[val].colores = item.colores;
          groups[val].idColor = item.idColor;
          groups[val].unidad = item.unidad;
          groups[val].precio = item.precio;
          groups[val].descuento = item.descuento;
          groups[val].tono = item.tono;
          groups[val].rollos += 1;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        let total = 0;

        const data = agrupadoValues.map((a) => {
          total = a.precio * a.cantidad;
          return [
            a.articulos,
            a.colores,
            a.tono,
            a.rollos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 140, 15, 25, 25);
        doc.text(`Devolución # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Cliente ${clientes}`, 20, 30);
        doc.text(`Surtido # ${surtidos}`, 20, 35);
        doc.text(
          `Metros ${new Intl.NumberFormat("en-US").format(
            TM
          )} / Kilos ${new Intl.NumberFormat("en-US").format(
            TK
          )} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`,
          20,
          40
        );
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Color",
              "Tono",
              "Rollos",
              "Cantidad",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "",
              "",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(subTotal),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              `IVA ${impuestos}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        // doc.save(`Devolucion-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Empresa", field: "empresas", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Nota", field: "surtidos", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Aplicada", field: "aplicada", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.empresas.toLowerCase().includes(search.toLowerCase()) ||
          comment.aplicada.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.surtidos.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresas.includes(selectedEmpresa)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }
    if (selectedAplicada) {
      computedComments = computedComments.filter((e) =>
        e.aplicada.includes(selectedAplicada)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedImpuesto) {
      computedComments = computedComments.filter(
        (e) => e.impuestos == selectedImpuesto
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "surtidos" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "surtidos" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "surtidos" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedEmpresa,
    selectedColaborador,
    selectedAplicada,
    selectedImpuesto,
  ]);

  const handleChangeInput = (id, event) => {
    const newArticulosDev = articulosDev.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.total = parseFloat(event.target.value) * parseFloat(i.cantidad);
      }
      return i;
    });
    setArticulosDev(newArticulosDev);
    ActualizaTotalesPrecio();
  };

  function ActualizaTotalesPrecio() {
    let importes = articulosDev.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (impuestos != 0) {
      setIva((impuestos * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * TG) / 100 + TG;

    setTotalGeneralEdit(totTemp);
  }

  const saveDevolucion = async (event) => {
    event.preventDefault();

    toggleProgreso();
    let totalPedido = articulosDev.length;

    try {
      await axios
        .patch(
          `${URL_DEVOLUCIONES}/${idEdit}`,
          {
            subTotal,
            iva,
            total_general: total_general_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          articulosDev.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_DEVOLUCIONES}/${a.id}`,
                {
                  precio: parseFloat(a.precio),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `${numero}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", `success`);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    });
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response}`,
      });
      console.log(error);
    }
  };

  function aplicarDevolucion(
    idDev,
    total_general,
    numero,
    idClientes,
    cliente,
    idColaboradores
  ) {
    setIdEdit(idDev);
    setTotalGeneralEdit(total_general);
    setCliente(idClientes);
    setClienteName(cliente);
    setNumero(numero);
    setTotal(0);
    setVendedor(idColaboradores);

    axios
      .get(`${URL_SURTIDOS}Cliente/${idClientes}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.idSurtido,
              importe: 0,
              clientes: a.clientes[0]._id,
              observaciones: "NA",
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
        console.log("inpfiel", dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleAplicaDevolucion();
  }

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (event.target.value <= i.saldo || event.target.value == "") {
          i[event.target.name] = parseFloat(event.target.value);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);
    setTotal(TE);
  }

  const handleChangeInputObser = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function savePago() {
    if (total == total_general_edit) {
      Swal.fire({
        title: "Estas seguro?",
        text: "Se registrará el abono",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.isConfirmed) {
          toggleProgreso();
          let totalMails = inputFields.length;
          inputFields.map((a) => {
            if (a.importe > 0) {
              axios
                .post(
                  URL_ABONOS,
                  {
                    fecha: endDate,
                    importe: a.importe,
                    clientes: cliente,
                    colaboradores: vendedor,
                    surtidos: a.id,
                    formaPago: "Devolucion",
                    concepto: "Devolucion",
                    bancos: BANCO_DEVOLUCION,
                    comisionPagada: "No",
                    observaciones: a.observaciones,
                    codigoAgrupado,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data) => {
                  axios.patch(
                    `${URL_SURTIDOS}/${a.id}`,
                    {
                      saldo: a.saldo - a.importe,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  axios.patch(
                    `${URL_DEVOLUCIONES}/${idEdit}`,
                    {
                      aplicada: "Si",
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                  console.log(error);
                });
            } else {
              totalMails = totalMails - 1;
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El Importe a aplicar no coincide con el total de la Devolucion",
      });
    }
  }

  // function desarrollo(){
  //   comments.map((a)=>{
  //     axios.patch(
  //       `${URL_DEVOLUCIONES}/${a._id}`,
  //       {
  //         aplicada: "Si"
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       }
  //     )
  //     .then(()=>{
  //       Swal.fire("Good job!", "Actualizado con exito", "success");
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //         footer: `${error.response.data}`,
  //       });
  //       console.log(error);
  //     });
  //   })
  // }
  let totalTablaImporte = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_devolucionesAdmin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Devoluciones</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Devoluciones</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Impuestos</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedImpuesto}
                onChange={(e) => {
                  setSelectedImpuesto(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value={16}>Si</option>
                <option value={0}>No</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedEmpresa}
                  onChange={(e) => {
                    setSelectedEmpresa(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {empresas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAplicada}
                  onChange={(e) => {
                    setSelectedAplicada(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData
              .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
              .map((a) => {
                totalTablaImporte = totalTablaImporte + a.total_general;
                {
                  return (
                    <tr>
                      {/* <td>{a.activo}</td> */}
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.empresas}</td>
                      <td>{a.clientes}</td>
                      <td>{a.surtidos}</td>
                      <td>{a.colaboradores}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.total_cantidad
                        )}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total_general)}
                      </td>
                      {/* <td>{a.total_general}</td> */}
                      <td>{a.aplicada}</td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(
                              a.fecha,
                              a.clientes,
                              a.numero,
                              a._id,
                              a.impuestos,
                              a.area,
                              a.subTotal,
                              a.iva,
                              a.total_general,
                              a.total_cantidad,
                              a.surtidos
                            )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                        {a.aplicada == "No" ? (
                          <>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditDevolucion(
                                  a._id,
                                  a.total_general,
                                  a.impuestos,
                                  a.clientes,
                                  a.numero,
                                  a.subTotal,
                                  a.iva
                                )
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                              {a.impuestos > 0 ? (
                                <Button
                                color="primary"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                              ):(
                            <Button
                              color="primary"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                aplicarDevolucion(
                                  a._id,
                                  a.total_general,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.idColaboradores
                                )
                              }
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                              )}
                            
                          </>
                        ) : undefined}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  Total
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(totalTablaImporte)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          <Modal
            size="xl"
            isOpen={modalEditArticulos}
            toggle={toggleEditArticulos}
          >
            <ModalHeader toggle={toggleEditArticulos}>
              <h4>Editar Precios de Devolucion #{numero}</h4>
              <h4>Cliente {clienteName}</h4>
              <h4 align="right">
                SubTotal{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(subTotal)}
                </Badge>{" "}
                Iva{"  "}
                {impuestos}%{" "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(iva)}
                </Badge>{" "}
                Total Devolucion{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(total_general_edit)}
                </Badge>
              </h4>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={saveDevolucion}>
                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Articulos</Label>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Color</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {articulosDev.map((ad) => (
                  <div key={ad.id}>
                    <Row>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="articuloName"
                          type="text"
                          value={ad.articuloName}
                          disabled
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="colorName"
                          type="text"
                          value={ad.colorName}
                          disabled
                        />
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Total Piezas"
                          value={ad.cantidad}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={ad.precio}
                          required
                          onChange={(event) => handleChangeInput(ad.id, event)}
                        />
                      </Col>

                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="total"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Total"
                          value={ad.total}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
              </Form>
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="xl"
            isOpen={modalAplicaDevolucion}
            toggle={toggleAplicaDevolucion}
          >
            <ModalHeader toggle={toggleAplicaDevolucion}>
              <h4>Aplicar Devolucion #{numero}</h4>
              <h4>Cliente {clienteName}</h4>
              <h4 align="right">
                Total Devolucion{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  }).format(total_general_edit)}
                </Badge>
              </h4>
            </ModalHeader>
            <ModalBody>
              {/* <Form onSubmit={savePago}> */}

              <Table striped borderless>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nota</th>
                    <th>Total</th>
                    <th>Abonos</th>
                    <th>Saldo</th>
                    <th>Importe</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {inputFields.map((a) => (
                    // <div key={a.id}>
                    <tr>
                      <td>{a.fecha}</td>
                      <td>{a.nota}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total_general)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total_general - a.saldo)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.saldo)}
                      </td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="number"
                          min="0"
                          step="any"
                          name="importe"
                          value={a.importe}
                          key={a.id}
                          onChange={(e) => {
                            handleChangeInputImporte(a.id, e);
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="text"
                          name="observaciones"
                          value={a.observaciones}
                          key={a.id}
                          onChange={(e) => {
                            handleChangeInputObser(a.id, e);
                          }}
                        />
                      </td>
                    </tr>
                    // </div>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="negrita" align="center">
                      Total
                    </td>
                    <td className="negrita">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(total)}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
                <br />
              </Table>

              <br />
              {validaBoton ? (
                <Button className="btn btn-success" onClick={savePago}>
                  {" "}
                  Registrar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              {/* </Form>      */}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoDevolucionesAdmin;
